import { useMutation, useQuery } from '@tanstack/react-query'
import xcpemCyber from '../axiosInstances/xcpemCyber'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { commonCondition } from '../lib/utils'
import { AdminLog } from '../models/Xcpem'

export const useGatewayParentalLogs = (
  currentPage: any,
  limit?: any
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['ParentalLog'],
    async () => {
      const email = localStorage.getItem('email')

      if (email === null) return []

      const endpoint = `/api/${
        commonCondition ? 'v4/ui' : 'v1'
      }/parental-control/actionlog/details?email=${encodeURIComponent(
        email
      )}&limit=${limit}&offset=${currentPage}&total=true`
      try {
        const response = await xcpemCyber.get<AdminLog[]>(endpoint)
        const newData = [{ data: response.data }, { total: response.headers['content-range'] }]

        return newData || []
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Parental log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayAdminLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['adminlogs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&limit=${limit}&order=timestamp.desc&offset=${currentPage}&event=not.is.null`
      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Admin log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayAllAdminLogs = (
  activeGw_Uid: string
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['alladminlogs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&order=timestamp.desc&event=not.is.null`
      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Admin log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayActivityLogs = (
  activeGw_Uid: any,
  limit?: any,
  offset?: any
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['activity-logs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_activity_log?gw_uid=eq.${gw_uid}&limit=${limit}&order=timestamp.desc&offset=${offset}`
      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Activity log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGatewayAllActivityLogs = (
  activeGw_Uid: any
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['allActivitylogs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_activity_log?gw_uid=eq.${gw_uid}&order=timestamp.desc`
      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`All activity log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useThreatsLogMail = () => {
  const putRequest: any = async (deviceId: any) => {
    const email: any = localStorage.getItem('email')
    try {
      const response = await xcpemSec.put(
        `/api/v1/panel/threats?email=${encodeURIComponent(email)}&device_id=${deviceId}`
      )
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Threats mail: ${error?.response?.data?.message || error?.message}`)
        throw error?.response?.data?.message
      }
    }
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(putRequest)

  return { mutate, isLoading, error }
}

export const useGatewayThreatsLogs = (
  activeGw_Uid: any,
  limit?: any,
  page?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['threattablogs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const email = localStorage.getItem('email')
      const mac = localStorage.getItem('mac')

      if (gw_uid === null || email === null || mac === null) return []

      // const endpoint = `/api/v1/panel/threats?email=${encodeURIComponent(
      const endpoint = `${
        process.env.REACT_APP_REALM === 'Hitron' ? '/api/v1/panel/threats' : '/api/v4/ui/cyber/threats/details'
      }?email=${encodeURIComponent(email)}&router_macid=${mac}&offset=${limit}&page=${page + 1}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Threats log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayAllThreatsLogs = (
  activeGw_Uid: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['threattab-alllogs'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const email = localStorage.getItem('email')
      const mac = localStorage.getItem('mac')

      if (gw_uid === null || email === null || mac === null) return []

      const endpoint = `/api/v1/panel/threats?email=${encodeURIComponent(email)}&router_macid=${mac}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Threats log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayIotLogs = (
  from: string,
  till: string,
  currentPage?: number,
  topic?: string,
  limit?: any,
  activeGw_Uid?: string
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['iotlogs'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gwuid === null) return []

      const endpoint = commonCondition
        ? `/api/v4/iot/raw_log?gw_uuid=${gwuid}&timestamp_from=${from}&timestamp_till=${till}&limit=${limit}&offset=${currentPage}&total=false&topic_filter=${topic}&sort=desc`
        : `/raw_log?topic=like.*${gwuid}*&timestamp=gt.${from}&timestamp=lt.${till}&limit=${limit}&offset=${currentPage}&topic=like.*${topic}*`
      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`IoT logs: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGatewayIotLogsCount = (
  from: string,
  till: string
): {
  data: AdminLog[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['iotlogsCount'],
    async () => {
      const gw_uid = localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = commonCondition
        ? `/api/v4/iot/raw_log?gw_uuid=${gw_uid}&timestamp_from=${from}&timestamp_till=${till}`
        : `/raw_log?topic=like.*${gw_uid}*&timestamp=gt.${from}&timestamp=lt.${till}`

      try {
        const response = await xcpemSec.get<AdminLog[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`IoT log count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
