import { Box, Typography } from '@mui/material'
import AppLogo from '../../../common-images/app-logo/logo'
import EarthLinkLogo from '../../../common-images/earthlink-logo/earthlink-logo-2.png'
import { getColorCode } from '../../../utils/helper'
import hitronHeaderImg from './HITRON-pb-lockup-rgb.svg'

const FooterSection = ({ className = 'bg-white' }) => {
  return (
    <Box
      className={className}
      position={'sticky'}
      bottom={0}
      left={0}
      width={'100%'}
      padding={'10px 20px'}
      zIndex={'9'}
      borderTop={'2px solid #eff2fc'}
    >
      <Box
        className='font-bold'
        textAlign={'right'}
        paddingRight={5}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* Powered by Hitron */}
        <Box>
          <Typography color={getColorCode('infoBlue')} fontWeight={600}>
            Version{' '}
            {process.env.REACT_APP_REALM === 'earthlink' ||
            process.env.REACT_APP_REALM === 'Customer' ||
            process.env.REACT_APP_TITLE === 'Hitron'
              ? '2.7.0'
              : '2.6.0'}
          </Typography>
        </Box>
        {process.env.REACT_APP_MODE === 'local' && process.env.REACT_APP_REALM !== 'earthlink' && (
          <AppLogo size={'150'} />
        )}
        {process.env.REACT_APP_MODE === 'hitron' && (
          <img src={hitronHeaderImg} alt='' width={'130px'} style={{ marginLeft: 5 }} />
        )}
        {process.env.REACT_APP_REALM === 'earthlink' && (
          <img src={EarthLinkLogo} alt='' width={'150px'} height={'40px'} style={{ marginLeft: 5, height: '40px' }} />
        )}
      </Box>
    </Box>
  )
}

export default FooterSection
