import TablePagination from '@mui/material/TablePagination'
import { CloseCircle, TickCircle } from 'iconsax-react'
import moment from 'moment'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useStagingMonitoring, useStagingWebMonitoringTotal } from '../../../../hook/parental-control/useParental'
import { currentISOTime, getColorCode } from '../../../../utils/helper'
import WebSuperVisionTile from '../web-supervision/web-supervision-tile'
import SocialPieChart from '../charts/social-supervision-pie-chart'
import './styles.scss'
import LoadingSpinner from '../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { Box } from '@mui/material'
import { useSessionContex } from '../../../../SessionContex'

interface Props {
  email: string
  dataFilter: 'today' | 'thisWeek' | 'thisMonth'
  deviceFilter: any
  showSocialChart: boolean
  fromDate?: any
}

const ParentalControlTableStaging: FC<Props> = ({ email, dataFilter, deviceFilter, showSocialChart, fromDate }) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [webSelected, setWebSelected] = useState<any>(0)
  // const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const { data, isLoading, refetch, isFetching, error } = useStagingMonitoring(
    fromDate || currentISOTime(),
    email,
    deviceFilter,
    offset,
    limit,
    webSelected
  )

  const {
    data: webChart,
    isFetching: webFetching,
    refetch: chartRefetch
  } = useStagingWebMonitoringTotal(fromDate ? fromDate : currentISOTime(), email, deviceFilter)

  const [newData, setNewData] = useState<any>([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    } else {
      if (data) {
        if (data[0].data) {
          setNewData(data[0].data)
        }

        if (!data[0].data) {
          setNewData([])
        }
      }
    }
    !isFetching &&
      setTimeout(() => {
        setLoader(false)
      }, 1000)
  }, [data, isFetching])

  useEffect(() => {
    setLoader(true)
    setCurrentPage(0)
    setOffset(0)
    refetch()
    chartRefetch()
  }, [deviceFilter, dataFilter, fromDate])

  useEffect(() => {
    setLoader(true)
    setCurrentPage(0)
    setOffset(0)
    refetch()
  }, [webSelected])

  useEffect(() => {
    if (reCall) {
      refetch()
      chartRefetch()
    }
  }, [reCall])

  useEffect(() => {
    refetch()
  }, [offset, limit])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }

  const sum = webChart?.reduce((accumulator: any, item: any) => accumulator + item.visit_count, 0) || 1

  const onWebClick = (selected: any) => {
    setWebSelected(webSelected !== selected?.category_id ? selected?.category_id : 0)
  }

  return (
    <div className='bg-white table-main-wrapper'>
      <div className='overflow-x-auto pb-3 mb-[16px] px-6 py-4 table-inner-wrapper'>
        {webFetching ? (
          <>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_loading_tile_${index}`}
                  value={'-' as unknown as number}
                  percentage={100}
                  title={'-'}
                  animate={true}
                />
              )
            })}
          </>
        ) : webChart && webChart?.length !== 0 ? (
          webChart
            ?.sort((a: any, b: any) => b.visit_count - a.visit_count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.visit_count}
                  percentage={Math.ceil((item.visit_count / sum) * 100)}
                  title={item.category_name}
                  id={item.category_id}
                  onClick={() => onWebClick(item)}
                  active={webSelected}
                />
              )
            })
        ) : (
          <Box
            color={getColorCode('redToBlue')}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            width={'100%'}
          >
            Web chart count not found
          </Box>
        )}
      </div>
      {/* {!isFetching && (data?.results?.web_chart_items.length === 0 || error !== null) && (
        <Box color={getColorCode('redToBlue')} display={'flex'} justifyContent={'center'} textAlign={'center'}>
          Web chart count not found
        </Box>
      )} */}
      {showSocialChart && (
        <SocialPieChart email={email} deviceFilter={deviceFilter} dataFilter={dataFilter} fromDate={fromDate} />
      )}
      <table className='pc-table'>
        <thead>
          <tr>
            <td>Allowed</td>
            <td>Visit Counts</td>
            <td>Category</td>
            <td>Website</td>
            <td>Device Name</td>
            <td>Time</td>
          </tr>
        </thead>
        <tbody>
          {(isFetching || loader) && (
            <tr>
              <td colSpan={6} style={{ width: '100%' }}>
                <Box display={'flex'} justifyContent={'center'}>
                  <LoadingSpinner />
                </Box>
                {/* <span className='animate-pulse mx-auto'>Loading</span> */}
              </td>
            </tr>
          )}

          {!isFetching &&
            !loader &&
            newData &&
            newData.length !== 0 &&
            newData
              ?.sort((a: any, b: any) => {
                if (a.start_datetime > b.start_datetime) return -1
                if (a.start_datetime < b.start_datetime) return 1
                return 0
              })
              //?.slice(offset, offset + limit)
              .map((row: any, key: any) => {
                // const time = moment.utc(row.start_datetime).local().format('D.M.YYYY HH:mm')
                const time = moment
                  .utc(row.start_datetime)
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                // moment(row.start_datetime).format('D.M.YYYY hh:mm A').split(' ')
                return (
                  <tr key={key}>
                    <td>
                      {(row?.is_website_blocked || row?.is_category_blocked) ? (
                        <CloseCircle size='32' color={getColorCode('redToYellow')} variant='Bold' />
                      ) : (
                        <TickCircle size='32' color={getColorCode('greenToGreen')} variant='Bold' />
                      )}
                    </td>
                    <td>{row?.visit_count || '-'}</td>
                    <td>{row?.category_name || '-'}</td>
                    <td>{row?.website || row?.domain_name || '-'}</td>
                    <td>{row?.device_name !== '' ? row?.device_name : 'unknown'}</td>
                    <td>
                      {time}
                      {/* {time[0]}
                      <br />
                      {time[1] + ' ' + time[2]} */}
                    </td>
                  </tr>
                )
              })}
          {!isFetching && !loader && newData?.length === 0 && (
            <tr>
              <td colSpan={6} align='center'>
                <Box display={'flex'} justifyContent={'center'} textAlign={'center'} color={getColorCode('redToBlue')}>
                  No Data found
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isFetching && newData && newData?.length !== 0 && (
        <TablePagination
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[3, 6, 10, 20, 50]}
          component='div'
          count={(data && parseInt(data[1]?.total?.split('/')[1])) || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default ParentalControlTableStaging
