import xcpemSec from '../../axiosInstances/xcpemSec'
import { notifyError } from '../../components/Toaster'

interface RangeFilterProps {
  limit: number
  offset: number
}

export const getCustomerStatistics = ({ limit, offset }: any): any => {
  return async (dispatch: any) => {
    const basUrl = `/webapp_white_labels_list?limit=${limit}&offset=${offset}`
    try {
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_REQUEST' }) // Set loading to true
      const response = await xcpemSec.get(`${basUrl}`)

      const deviceStatistics = response.data
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
        // localStorage.setItem('error', 'true')

        return error?.response?.status
      } else {
        notifyError(`Customer Statistics: ${error?.response?.data?.message || error?.message}`)
        dispatch({ type: 'GET_CUSTOMER_STATISTICS_FAILURE', error: error.message, payload: [] })
      }
    }
  }
}

export const getCustomerStatisticsCount = (whiteLable?: string | null | undefined): any => {
  return async (dispatch: any) => {
    const basUrl = whiteLable ? `/webapp_white_labels_list?white_label=eq.${whiteLable}` : `/webapp_white_labels_list`
    try {
      dispatch({ type: 'GET_CUSTOMER_STATISTICS_COUNT_REQUEST' }) // Set loading to false
      const response = await xcpemSec.get(`${basUrl}`)
      const deviceStatistics = response.data

      dispatch({ type: 'GET_CUSTOMER_STATISTICS_COUNT_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
        // localStorage.setItem('error', 'true')

        return error?.response?.status
      } else {
        notifyError(`Customer Statistics Count: ${error?.response?.data?.message || error?.message}`)
        dispatch({ type: 'GET_CUSTOMER_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}
