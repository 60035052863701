/* eslint-disable react-hooks/exhaustive-deps */
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import { DatePicker, Modal } from 'antd'
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ArrowDown2, ArrowUp2, Edit2, Eye, InfoCircle } from 'iconsax-react'
import moment from 'moment'
import { ngxCsv } from 'ngx-csv/ngx-csv'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import { useGatewayIotLogs } from '../../../../hook/useGatewayLogs'
import { commonCondition } from '../../../../lib/utils'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'
import ChangePassword from './ChangePassword'
import './deviceTab.scss'
import TelemetryChart from './telemetry/telemetry_chart'

const { RangePicker } = DatePicker

dayjs.extend(utc)

const DeviceTab = ({
  customerinfo,
  handleSubmit,
  onSubmit,
  fetchingRouterSettings,
  mqttRes,
  showPassword,
  editSSID,
  routerConfig,
  handleSSIDEditInfo,
  valuesChanged,
  hasErrors,
  register,
  errors,
  inputClassName,
  editPASSWORD,
  handlePasswordEditInfo,
  gatewayFeaching,
  setApiCallPASSWORD,
  setMailPass
}: any) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTelemetryModalOpen, setIsTelemetryModalOpen] = useState(false)
  const [shownLogs, setshownLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const fromDate = moment().subtract(24, 'hours')
  const tillDate = moment()
  const [dateValue, setDateValue] = useState<any>(null)
  const [topicFilter, setTopicFilter] = useState<any>('')
  const [allLogs, setAllLogs] = useState<any[]>([])
  const [pageLoader, setPageLoader] = useState(true)
  const [wanIP, setWanIP] = useState('N/A')
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    if (value === null) {
      setDateValue(null)
    } else {
      setDateValue(dateString)
    }
  }

  const {
    data: modalData,
    refetch,
    isFetching
  } = useGatewayIotLogs(
    commonCondition
      ? moment(dateValue === null || dateValue[0] === '' ? fromDate : dateValue[0])
          .utc()
          .toISOString()
      : moment(dateValue === null || dateValue[0] === '' ? fromDate : dateValue[0])
          .utc()
          .format('YYYYMMDDTHH:mm:ss'),

    dateValue === null || dateValue[1] === ''
      ? commonCondition
        ? moment(tillDate).utc().toISOString()
        : moment(tillDate).utc().format('YYYYMMDDTHH:mm:ss')
      : commonCondition
      ? moment(dateValue[1]).utc().toISOString()
      : moment(dateValue[1]).utc().format('YYYYMMDDTHH:mm:ss'),
    offset,
    topicFilter,
    50,
    gw_uid
  )

  useEffect(() => {
    if (modalData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [modalData])
  useEffect(() => {
    if (mqttRes && mqttRes !== '') {
      setWanIP(mqttRes?.params?.wan_ip)
    }
  }, [mqttRes])

  useEffect(() => {
    // Update all logs when new data is fetched
    if (isModalOpen) {
      currentPage === 1
        ? setAllLogs(modalData || [])
        : modalData && modalData.length !== 0 && setAllLogs([...allLogs, ...modalData])
    } else {
      setAllLogs([])
    }
  }, [modalData, isModalOpen])

  const handleCancel = () => {
    setDateValue(null)
    setTopicFilter('')
    setIsModalOpen(false)
    setCurrentPage(1)
    setOffset(0)
  }

  const handleTelemetryCancel = () => {
    setIsTelemetryModalOpen(false)
  }

  const togglecomment = (id: any) => {
    setshownLogs((prevshowncomments: any) => ({
      ...prevshowncomments,
      [id]: !prevshowncomments[id]
    }))
  }

  useEffect(() => {
    // setIsCyberLimitFound(false)
    // const getPcSettingByGateway = async () => {
    //   if (customerinfo) {
    //     const result = await pcSettingByGateway(customerinfo?.gw_uid)
    //     let pcLimitcountData = 0
    //     setIsPCLoading(1)
    //     setPcLimitCount(0)
    //     if (result && result.pc_settings) {
    //       const macAddresses = Object.keys(result.pc_settings)
    //       for (let index = 0; index < macAddresses.length; index++) {
    //         const deviceInfo = result.pc_settings[macAddresses[index]]
    //         if (
    //           deviceInfo.blocked_categories || //blocked_categories_count
    //           deviceInfo.blocked_domains || //blocked_domains_count
    //           deviceInfo.blocked_internet || //internet_blocked_till
    //           deviceInfo.limited_internet //internet_enabled
    //         ) {
    //           setIsCyberLimitFound(true)
    //           pcLimitcountData++
    //           setPcLimitCount(pcLimitcountData)
    //         }
    //       }
    //     } else {
    //       setIsPCLoading(2)
    //     }
    //   } else {
    //     setIsPCLoading(2)
    //   }
    // }
    // setIsPCLoading(0)
    // setPcLimitCount(0)
    if (customerinfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      // getPcSettingByGateway()
    }
    setPageLoader(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerinfo])

  const onPaginatedSearch = (e: any) => {
    //newPage * limit
    setOffset((currentPage + 1) * 50)
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!pageLoader) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [pageLoader])

  const handleInputChange = (e: any) => {
    setTopicFilter(e.target.value)
  }

  const disabledDate = (current: any) => {
    // Get the current date
    // const today = new Date()

    // Disable dates that are after today
    // return current && current > today
    // console.log('====', current > today)
    // return current > today
    // var oneDay = 1 * 24 * 60 * 60 * 1000
    return current && current.valueOf() > dayjs()
  }

  const downloadLogs = () => {
    const csvLogs =
      allLogs &&
      allLogs?.length !== 0 &&
      allLogs?.map((obj: any) => {
        obj.payload = JSON.stringify(obj.payload)
        return obj
      })

    new ngxCsv(csvLogs, 'Xunison_logs_' + new Date().getTime(), {
      title: 'Xunison',
      headers: ['msgid', 'clientid', 'timestamp', 'qos', 'topic', 'payload']
    })
  }

  useEffect(() => {
    if (customerinfo && !gatewayFeaching) {
      setTimeout(() => {
        setPageLoader(false)
      }, 500)
    } else {
      setPageLoader(true)
    }
  }, [gatewayFeaching, customerinfo])

  useEffect(() => {
    if (isModalOpen) {
      refetch()
    }
  }, [isModalOpen])

  useEffect(() => {
    if (currentPage !== 1 && offset !== 0 && isModalOpen) {
      refetch()
    }
  }, [currentPage, offset, isModalOpen])

  return (
    <>
      <div className='border-top'>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Account</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              (customerinfo && customerinfo?.email) || '-'
            )}
          </label>
          <label className=''></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>First Connection</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              (customerinfo &&
                customerinfo?.user_first_connection_dt &&
                moment
                  .utc(customerinfo?.user_first_connection_dt)
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm:ss A`)) ||
              '-'
            )}
          </label>
          <label className=''></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Registration Date</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              (customerinfo &&
                customerinfo?.provision_timestamp &&
                !customerinfo.provision_timestamp.startsWith('1970') &&
                moment
                  .utc(customerinfo?.provision_timestamp)
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm:ss A`)) ||
              'NA'
            )}
          </label>
          <label className=''></label>
        </div>
        {customerinfo?.dev_mesh_role === 'CAP' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex info-div align-items-center' style={{ padding: '6px 10px 0px 20px' }}>
              <label className='info-name-cls'>SSID</label>
              {fetchingRouterSettings ? (
                <>
                  <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                  <label className=''></label>
                </>
              ) : (
                <>
                  {customerinfo?.is_online ? (
                    <label className='info-desc-cls d-flex justify-content-between'>
                      {customerinfo?.dev_mesh_role === 'CAP' ? (
                        <input
                          type='text'
                          disabled={!editSSID}
                          defaultValue={routerConfig?.SSID}
                          {...register('ssid', {
                            required: 'SSID is required.',
                            minLength: {
                              value: 2,
                              message: 'SSID must be at least 2 character long.'
                            },
                            maxLength: {
                              value: 32,
                              message: 'SSID must not exceed 32 characters.'
                            },
                            pattern: {
                              // value: /^[A-Za-z0-9]*[A-Za-z0-9]$/,
                              // value: /^[a-zA-Z0-9][a-zA-Z0-9\S]*[a-zA-Z0-9\S]$/,
                              // value: /^[a-zA-Z0-9][a-zA-Z0-9_-]*(?<!\s)$/,
                              value:
                                process.env.REACT_APP_REALM !== 'Hitron'
                                  ? /^[a-zA-Z0-9][a-zA-Z0-9!@#$^&*()_+\-={}[\]|<>?/,.]{0,30}[a-zA-Z0-9]$/
                                  : /^[a-zA-Z0-9][a-zA-Z0-9_-]*(?<!\s)$/,
                              message:
                                process.env.REACT_APP_REALM !== 'Hitron'
                                  ? `SSID must be 2-32 characters long, start and end must be a letter or number, and rest can include letters, numbers or following special characters: !@#$^&*()_+-={}[]|<>?/,.. Spaces, quotes (" or '), percent (%), backslash (\\), colon (:), and semicolon (;) are not allowed.`
                                  : 'SSID must start with a letter or number and can include letters, numbers, underscores (_), and hyphens (-). Spaces and trailing spaces are not allowed.'
                            }
                          })}
                          className='ssidinput form-control'
                        />
                      ) : (
                        <label className='info-desc-cls'>{routerConfig?.SSID}</label>
                      )}
                      {customerinfo?.dev_mesh_role === 'CAP' && (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#707e8c'
                              }
                            }
                          }}
                          title={<Typography>Edit</Typography>}
                        >
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                          >
                            <Edit2
                              size={16}
                              onClick={handleSSIDEditInfo}
                              color={editSSID ? '#282828' : '#6B7A9F'}
                              className=''
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {valuesChanged && !hasErrors ? (
                        <button
                          type='submit'
                          className='applybtn ms-2'
                          style={{
                            background: getColorCode('greenToBlue'),
                            color: 'white',
                            border: `1px solid ${getColorCode('greenToBlue')}`
                            // '&:hover': {
                            //   border: `1px solid ${getColorCode('greenToBlue')}`,
                            //   color: getColorCode('greenToBlue'),
                            //   background: 'transparent'
                            // }
                          }}
                        >
                          Apply
                        </button>
                      ) : hasErrors ? (
                        <span
                          data-tooltip-id='alert-type'
                          data-tooltip-html={`<div style='color:#FFFFFF'>${
                            errors?.password?.message || errors?.ssid?.message
                          }</div>`}
                          className=' d-flex  align-items-center ms-1'
                        >
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#707e8c'
                                }
                              }
                            }}
                            title={
                              errors?.ssid?.message ||
                              `Up to 32 alphanumeric, case sensitive, characters. First character must be a letter or number, Tab and trailing whitespace cannot be used`
                            }
                          >
                            <InfoCircle size='18' color='#FF8A65' />
                          </Tooltip>
                        </span>
                      ) : null}
                    </label>
                  ) : (
                    <label className='info-desc-cls py-2'>NA</label>
                  )}
                </>
              )}
            </div>

            <div className='d-flex info-div align-items-center' style={{ padding: '6px 10px 0px 20px' }}>
              <label className='info-name-cls'>Password</label>
              {fetchingRouterSettings ? (
                <>
                  <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                  <label className=''></label>
                </>
              ) : (
                <>
                  {customerinfo?.is_online ? (
                    <label className='info-desc-cls d-flex justify-content-between position-relative'>
                      {customerinfo?.dev_mesh_role === 'CAP' ? (
                        <>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            // disabled={!editPASSWORD}
                            disabled
                            className='ssidinput form-control'
                            defaultValue={routerConfig?.PASSWORD}
                            {...register('password', {
                              required: 'Password is required.',
                              minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters long.'
                              },
                              maxLength: {
                                value: 63,
                                message: 'Password must not exceed 63 characters.'
                              },
                              pattern: {
                                value: /^[a-zA-Z0-9!@#$^&*()_+\-={}[\]|<>?/,.]{8,63}$/,
                                message: `Password must be 8-63 characters long, and can include the following special characters: !@#$^&*()_+-={}[]|<>?/,. Spaces, quotes (" or '), percent (%), backslash (\\), colon (:), and semicolon (;) are not allowed.`
                              }
                            })}
                          />

                          {/* <div className='iconContainer'>
                          {!showPassword ? (
                            <Eye size='16' color='#6B7A9F' onClick={handleShowPassword} />
                          ) : (
                            <EyeSlash size='16' color='#6B7A9F' onClick={handleShowPassword} />
                          )}
                        </div> */}
                        </>
                      ) : (
                        <>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            disabled={true}
                            className={`${inputClassName} form-control`}
                            defaultValue={routerConfig?.PASSWORD}
                          />
                          {/* <div className='iconContainer'>
                          {!showPassword ? (
                            <Eye size='16' color='#6B7A9F' className='mt-2' onClick={handleShowPassword} />
                          ) : (
                            <EyeSlash size='16' color='#6B7A9F' className='mt-2' onClick={handleShowPassword} />
                          )}
                        </div> */}
                        </>
                      )}
                      {customerinfo?.dev_mesh_role === 'CAP' && (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#707e8c'
                              }
                            }
                          }}
                          title={<Typography>Edit</Typography>}
                        >
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                          >
                            <Edit2
                              size={16}
                              color={editPASSWORD ? '#282828' : '#6B7A9F'}
                              className=''
                              onClick={handlePasswordEditInfo}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </label>
                  ) : (
                    <label className='info-desc-cls py-2'>NA</label>
                  )}
                </>
              )}
            </div>
          </form>
        )}
        {editPASSWORD && (
          <ChangePassword
            isOpen={editPASSWORD}
            onClose={handlePasswordEditInfo}
            onSubmit={onSubmit}
            title={'Reset Password'}
            passwordValue={routerConfig?.PASSWORD}
            valuesChanged={valuesChanged}
            setApiCallPASSWORD={setApiCallPASSWORD}
            setMailPass={setMailPass}
          />
        )}
        {process.env.REACT_APP_MODE !== 'hitron' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>OTT</label>
            <label className='info-desc-cls'>
              {!customerinfo || pageLoader ? (
                <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
              ) : customerinfo?.ott_serial ? (
                customerinfo.ott_serial
              ) : (
                '--'
              )}
            </label>
            <label></label>
          </div>
        )}
        {/* <div className='d-flex info-div'>
          <label className='info-name-cls'>Serial Number</label>
          <label className='info-desc-cls'>{customerinfo?.gw_uid.replace(/[^x]/g, 'x')}</label>
          <label></label>
        </div> */}
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Device</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              customerinfo?.model
            )}
          </label>
          <label></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Gateway UID</label>
          <label className='info-desc-cls' title={customerinfo?.gw_uid || '-'}>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              customerinfo?.gw_uid
            )}
          </label>
          <label></label>
        </div>

        <div className='d-flex info-div'>
          <label className='info-name-cls'>MSO Name</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              customerinfo?.white_label || 'N/A'
            )}
          </label>
          <label></label>
        </div>

        <div className='d-flex info-div'>
          <label className='info-name-cls'>5G Router</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : customerinfo ? (
              process.env.REACT_APP_MODE !== 'hitron' ? (
                (customerinfo?.model === 'D50' || customerinfo?.model === 'D60') &&
                customerinfo?.lte_module !== 'Not installed' ? (
                  'YES'
                ) : (
                  'NO'
                )
              ) : customerinfo?.lte_module === '' || customerinfo?.lte_module === 'Not installed' ? (
                'NO'
              ) : (
                'YES'
              )
            ) : (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            )}
          </label>
          <label></label>
        </div>
        {customerinfo?.dev_mesh_role === 'CAP' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>WAN IP</label>
            <label className='info-desc-cls' title={wanIP || 'N/A'}>
              {!customerinfo || pageLoader ? (
                <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
              ) : customerinfo?.is_online ? (
                wanIP || 'N/A'
              ) : (
                'N/A'
              )}
            </label>
            <label></label>
          </div>
        )}
        <div className='d-flex info-div'>
          <label className='info-name-cls'>WAN MAC</label>
          <label className='info-desc-cls'>
            {!customerinfo || pageLoader ? (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            ) : (
              customerinfo?.wan_mac
            )}
          </label>
          <label></label>
        </div>
        {process.env.REACT_APP_MODE !== 'hitron' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>OTT MAC</label>
            <label className='info-desc-cls'>--</label>
            <label></label>
          </div>
        )}
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Mesh Role</label>
          {/* {fetchingRouterSettings ? (
            <>
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
              <label></label>
            </>
          ) : ( */}
          <>
            <label className='info-desc-cls'>
              {
                // routerConfig
                //   ? routerConfig?.MESH_ROLE === 'CAP'
                //     ? 'Master'
                //     : 'Satellite'
                //   :
                !customerinfo || pageLoader ? (
                  <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                ) : customerinfo?.dev_mesh_role === 'CAP' ? (
                  'Master'
                ) : (
                  'Satellite'
                )
              }
            </label>
            <label></label>
          </>
          {/* )} */}
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Gateway Logs</label>
          {!customerinfo || pageLoader ? (
            <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
          ) : (
            <>
              <label className='info-desc-cls'>Available</label>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#707e8c'
                    }
                  }
                }}
                title={<Typography>View</Typography>}
              >
                <Eye
                  size='16'
                  color='#6B7A9F'
                  className='cursor-pointer'
                  onClick={() => {
                    setAllLogs([])
                    setIsModalOpen(true)
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Telemetry</label>
          {!customerinfo || pageLoader ? (
            <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
          ) : (
            <>
              <label className='info-desc-cls'>Available</label>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#707e8c'
                    }
                  }
                }}
                title={<Typography>View</Typography>}
              >
                <Eye
                  size='16'
                  color='#6B7A9F'
                  className='cursor-pointer'
                  onClick={() => {
                    setIsTelemetryModalOpen(true)
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
        {/* {customerinfo && customerinfo?.dev_mesh_role === 'CAP' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>Parental Control </label>
            {fetchingRouterSettings ? (
              <>
                <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                <label></label>
              </>
            ) : isCyberLimitFound ? (
              <>
                <label
                  className='info-desc-cls cursor-pointer'
                  style={{ color: getColorCode('textGrayToBlue'), fontWeight: 'bold', textDecoration: 'underline' }}
                  onClick={() => {
                    navigate(`/dashboard/${customerinfo?.gw_uid}?email=${customerinfo?.email}`, { state: 'service' })
                    // setTimeout(() => {
                    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 5 })
                    dispatch({ type: 'SET_DEVICE_MAC_ID_REQUEST', payload: '' })
                    // }, 200)
                  }}
                >
                  Active 
                  ({`In ${pcLimitCount} ${pcLimitCount > 1 ? ' Devices' : ' Device'}`})
                </label>
                <label
                  className=' cursor-pointer'
                  onClick={() => {
                    navigate(`/dashboard/${customerinfo?.gw_uid}?email=${customerinfo?.email}`, { state: 'service' })
                    // setTimeout(() => {
                    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 5 })
                    dispatch({ type: 'SET_DEVICE_MAC_ID_REQUEST', payload: '' })
                    // }, 200)
                  }}
                  style={{ color: getColorCode('textGrayToBlue') }}
                >
                  <BreadcrumbsArrowRight color={getColorCode('textGrayToBlue')} />
                </label>
              </>
            ) : (
              (isPCLoading === 1 && <label className='info-desc-cls'>Not Active</label>) ||
              (isPCLoading === 0 && <i className='fa fa-refresh fa-spin fs-18'></i>) ||
              (isPCLoading === 2 && <label className='info-desc-cls'>Unknown</label>)
            )}
          </div>
        )} */}
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={800}
        wrapClassName={'authorize_gateway_wrapper'}
        centered={true}
      >
        <div className='authorize_gateway_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3
              style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              Gateway Logs
              <CachedIcon
                className='ml-3 cursor-pointer'
                onClick={() => {
                  refetch()
                }}
              />
            </h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                handleCancel()
              }}
            />
          </div>
          <div className='d-flex align-center flex-wrap row-gap-1 mb-3'>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <input type='text' value={topicFilter} onChange={handleInputChange} placeholder='Topic Filter' />
            <RangePicker
              // defaultValue={[dayjs().subtract(3, 'hour'), dayjs()]}
              showTime={{ format: 'hh:mm A' }}
              // format={`${process.env.REACT_APP_DATE_FORMAT} HH:mm`}
              onChange={onChange}
              disabledDate={disabledDate}
              value={dateValue !== null ? [dayjs(dateValue[0]), dayjs(dateValue[1])] : dateValue}
            />
            <Button
              type='submit'
              className={`ms-1 flex items-center`}
              sx={{
                background: getColorCode('greenToBlue'),
                color: 'white',
                border: `1px solid ${getColorCode('greenToBlue')}`,
                '&:hover': {
                  border: `1px solid ${getColorCode('greenToBlue')}`,
                  color: getColorCode('greenToBlue'),
                  background: 'transparent'
                },
                padding: '2px 10px',
                height: '32px'
              }}
              onClick={() => {
                // setAllLogs([])

                setCurrentPage(1)
                setOffset(0)
                setTimeout(() => {
                  if (!gw_uid) return
                  refetch()
                }, 500)
              }}
            >
              Apply
            </Button>
            <Button
              onClick={downloadLogs}
              className=''
              sx={{
                fontSize: '11px',
                padding: '5px 16px',
                color: getColorCode('redToYellow'),
                border: `1px solid ${getColorCode('redToYellow')} !important`,
                borderRadius: '5px'
              }}
            >
              Download Log File
            </Button>
            {/* </LocalizationProvider> */}
          </div>

          <div className='authorize_gateway_body logs_antd'>
            {isFetching ? (
              <Loader />
            ) : allLogs?.length !== 0 ? (
              allLogs?.map((activity: any, index: any) => (
                <div key={index} className='log-details-cls pt-2 pe-2 ps-3 pb-2 mb-2 align-items-center'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-grid'>
                      <label>
                        <span className='logs-header-cls'>Time: </span>{' '}
                        {moment
                          .utc(activity.timestamp)
                          .local()
                          .format(`${process.env.REACT_APP_DATE_FORMAT}, hh:mm:ss A`)}
                        (msgid:
                        {activity?.msgid})
                      </label>
                      <label>
                        <span className='logs-header-cls'>Client Id: </span>
                        {activity?.clientid}
                      </label>
                      <label>
                        <span className='logs-header-cls'>Topic: </span>
                        {activity?.topic}
                      </label>
                    </div>
                    <div>
                      <span onClick={() => togglecomment(activity.msgid)}>
                        {shownLogs[activity.msgid] ? (
                          <ArrowUp2 size='20' color='#e84e6a' />
                        ) : (
                          <ArrowDown2 size='20' color='#1abc9c' />
                        )}
                      </span>
                    </div>
                  </div>
                  {shownLogs[activity.msgid] && (
                    <span className='pre-text-cls'>{JSON.stringify(activity?.payload, null, 2)}</span>
                  )}
                </div>
              ))
            ) : (
              <div className='no-data-found text-center pt-4' style={{ color: getColorCode('redToBlue') }}>
                No data found
              </div>
            )}
            {!isFetching && modalData && modalData.length !== 0 && modalData.length <= 50 && (
              <div className='text-center justify-center d-flex'>
                <button type='button' className='load_more_btn' onClick={onPaginatedSearch}>
                  Load More
                  <span className='ml-2'>
                    <ArrowDown2 size='15' color='#0070f0' />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* Telemetry history */}
      <Modal
        open={isTelemetryModalOpen}
        footer={null}
        closable={false}
        width={1000}
        wrapClassName={'authorize_gateway_wrapper'}
        centered={true}
      >
        <div className='authorize_gateway_body_wrapper telemetry-chart-cls'>
          {/* <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3'>
            <h3>
              Telemetry History <CachedIcon className='ml-3' onClick={() => setRefresh(true)} />
            </h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                handleTelemetryCancel()
              }}
            />
          </div> */}
          <div>
            <TelemetryChart handleTelemetryCancel={handleTelemetryCancel} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeviceTab
