import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import NoInternet from '../../components/NoInternet'
import { useOnlineStatus } from '../../hook/useOnlineStatus'

type CommonLayoutProps = {
  children: ReactNode
}

const CommonLayout = ({ children }: CommonLayoutProps) => {
  const isOnline = useOnlineStatus()
  const location = useLocation()

  return (
    <>
      {!isOnline && <NoInternet />}
      <div className={`common-layout-wrapper ${location.pathname === '/login' ? 'overflow-y-hidden' : ''}`}>
        {children}
      </div>
      {/* <ToastContainer
        position='bottom-right'
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      /> */}
    </>
  )
}

export default CommonLayout
