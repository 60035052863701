import { FC, useState, useEffect } from 'react'
import { ConnectedDevice } from '../../../../models/Xcpem'
import { handlefingerprint } from '../../../../utils/fingerprinting'
import { CloseCircle, Lock1, Unlock } from 'iconsax-react'
import { getColorCode } from '../../../../utils/helper'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import * as React from 'react'
import './styles.scss'

interface Props {
  devices: any[]
  onChange: (deviceId: any) => void
  useMac?: boolean
  useDeviceInfo?: boolean
  reCall?: boolean
  filteringDevice?: any
}

const ThreatsTileStaging: FC<Props> = ({
  devices,
  onChange,
  useMac,
  useDeviceInfo,
  reCall,
  filteringDevice = '*'
}): any => {
  const [activeTile, setActiveTile] = useState<number | '*'>('*')

  useEffect(() => {
    if (reCall) {
      setActiveTile(typeof filteringDevice === 'object' ? filteringDevice.device_mac : filteringDevice)
    }
  }, [reCall])
  useEffect(() => {
    if (filteringDevice) {
      setActiveTile(typeof filteringDevice === 'object' ? filteringDevice.device_mac : filteringDevice)
    }
  }, [filteringDevice])

  useEffect(() => {
    if (devices && activeTile !== '*') {
      const data = devices.filter(obj => obj.device_mac === activeTile)
      data.length !== 0 && onChange(data[0])
    }
  }, [devices])

  const handleActiveTile = (deviceId: any) => {
    if (deviceId === '*') {
      setActiveTile('*')
      onChange('*')
    } else if (useDeviceInfo) {
      onChange(deviceId)
      setActiveTile(deviceId.device_mac || deviceId.mac)
    } else {
      setActiveTile(deviceId.device_mac || deviceId.mac)
      // if (useMac) {
      //   onChange(deviceId.device_macid)
      // } else {
      onChange(deviceId.device_mac || deviceId.mac)
      // }
    }
  }
  const redColor: any = getColorCode('redToBlue')
  const allTile = (
    <div
      key='threat_Tile_all'
      className={`cursor-pointer alltile-main bg-white rounded-[10px] py-[13px] px-[39px] text-center max-w-[153px] flex flex-col justify-center items-center gap-y-[11px] border-2 ${activeTile === '*' ? 'activeTile ' : ''
        }`}
      style={{ border: `${activeTile === '*' ? `2px solid ${getColorCode('redToGray')}` : ''}` }}
      onClick={() => handleActiveTile('*')}
    >
      <span
        className={`alltile-inner overflow-hidden  overflow-ellipsis whitespace-nowrap min-w-[110px] w-full text-[14px]
            ${activeTile === '*' ? 'activeTileText font-sf-regular' : 'font-sf-regular text-layout-textOnSurface'}`}
        style={{ color: `${activeTile === '*' ? `${getColorCode('redToBlack')}` : ''}` }}
      >
        All Devices
      </span>
    </div>
  )

  const tiles =
    devices && devices.length !== 0
      ? [
        allTile,
        ...devices
          ?.sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1))
          .map((tile: ConnectedDevice, index: number) => {
            const isactiveTile = activeTile === (tile?.device_mac || tile.mac)
            const cls = `cursor-pointer alltile-main bg-white rounded-[10px] py-[13px] px-[39px] text-center max-w-[153px] relative flex flex-col justify-center items-center gap-y-[11px] border-2 ${isactiveTile === true ? 'activeTile ' : ''
              }`
            const fingerprintPayload = [
              tile?.os_name,
              tile?.device_name,
              tile?.fingerbank_device_name,
              tile?.os_parent_name,
              tile?.manufacturer_name
            ]
            const image = tile?.model?.toLowerCase()?.includes('aria')
              ? 'aria'
              : handlefingerprint(fingerprintPayload)

            return (
              <div
                key={`threat_Tile_${index}`}
                className={cls}
                onClick={() => handleActiveTile(tile)}
                style={{ border: `${isactiveTile === true ? `2px solid ${getColorCode('redToGray')}` : ''}` }}
              >
                <div className='w-[90px] h-[70px] rounded-full  flex items-center justify-center img-block'>
                  <img
                    src={`/images/network-map-new-hitron-images/${tile?.device_icon !== ''
                      ? tile?.device_icon !== 'unknown'
                        ? tile?.device_icon
                        : 'mobile2x'
                      : 'mobile2x'
                      }.png`}
                    alt={`${tile.device_name}`}
                    // className='img-block'
                    style={{ height: '100%' }}
                  />
                </div>
                <span
                  className={`alltile-inner overflow-hidden  overflow-ellipsis whitespace-nowrap min-w-[110px] w-full text-[14px]
            ${isactiveTile === true ? 'activeTileText  font-sf-regular' : 'font-sf-regular text-layout-textOnSurface'}`}
                  style={{ color: `${isactiveTile === true ? `${getColorCode('redToBlack')}` : ''}` }}
                >
                  {/* {tile?.device_name !== '' ? tile.device_name : 'unknown'} */}
                  {tile.device_name ? tile.device_name : 'unknown'}
                </span>

                <span className='absolute top-1 left-1 bottomBlock'>
                  <FiberManualRecordIcon
                    sx={{
                      color:
                        tile.is_active === true
                          ? getColorCode('Closed')
                          : tile.is_active === false
                            ? getColorCode('redToGray')
                            : getColorCode('Closed'),
                      verticalAlign: 'bottom'
                    }}
                  />
                </span>

                <span
                  className={`absolute top-1 right-1 bottomBlockRight`}
                  style={{
                    color: tile.blocked_socials_count === 0 &&
                      tile.blocked_categories_count === 0 &&
                      tile.blocked_domains_count === 0 &&
                      tile.internet_blocked_till === null &&
                      tile.internet_enabled === true &&
                      tile.internet_restrictions_schedule_enabled === false
                      ? getColorCode('Closed')
                      : getColorCode('redToRed')
                  }}
                >
                  {tile.blocked_socials_count === 0 && tile.blocked_categories_count === 0 &&
                    tile.blocked_domains_count === 0 &&
                    tile.internet_blocked_till === null &&
                    tile.internet_enabled === true &&
                    tile.internet_restrictions_schedule_enabled === false ? (
                    ''
                  ) : (
                    <Lock1 size={18} />
                  )}
                </span>
              </div>
            )
          })
      ]
      : []

  return tiles
}

export default ThreatsTileStaging
