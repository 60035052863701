import { useNavigate } from 'react-router-dom'

const SidebarCollapseHitronLogo = ({ className }: { className: string }) => {
  const navigation = useNavigate()
  return (
    <div
      className={className}
      onClick={() => {
        navigation('/dashboard')
      }}
    >
      <svg width='69' height='50' viewBox='0 0 69 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M55.1051 3.19041C50.8647 0.0312431 44.6859 0.405187 38.729 3.56697L39.2437 4.44141C42.4547 3.75269 45.4452 4.11893 47.8047 5.72276C54.3538 10.1738 53.5985 22.4484 46.1216 33.1322C44.0536 36.086 41.6914 38.6078 39.2129 40.6213L39.7156 41.3916C44.5094 39.6915 49.4129 36.1084 53.311 31.0278C61.1769 20.7786 61.979 8.31965 55.1051 3.19041Z'
          fill='#009BE5'
        />
        <path
          d='M30.0345 46.8102C34.2829 49.9693 40.459 49.594 46.4132 46.4335L45.8972 45.5592C42.6915 46.2479 39.6957 45.8831 37.3362 44.2778C30.7858 39.8241 31.5411 27.5522 39.0219 16.8697C41.0886 13.9107 43.4495 11.3915 45.9279 9.38064L45.4212 8.61157C40.6328 10.3103 35.7293 13.8909 31.8312 18.9727C23.964 29.2246 23.1606 41.6836 30.0345 46.8102Z'
          fill='#009BE5'
        />
        <path
          d='M61.5659 17.9912L61.1702 19.3029C62.5912 20.1457 63.3893 21.0741 63.3893 22.0578C63.3893 25.7411 52.2068 28.7317 38.4135 28.7317C24.6162 28.7317 13.4338 25.7411 13.4338 22.0578C13.4338 19.2344 19.9895 16.8232 29.2429 15.8461L31.2308 14.0024C14.2639 14.4818 1 18.6813 1 23.7868C1 29.2137 15.9978 33.6173 34.4967 33.6173C53.0009 33.6173 68 29.2137 68 23.7868C68 21.6206 65.6071 19.6189 61.5659 17.9912Z'
          fill='#AACD06'
        />
      </svg>
    </div>
  )
}

export default SidebarCollapseHitronLogo
