// import TablePagination from '@mui/material/TablePagination'
// import { CloseCircle, TickCircle } from 'iconsax-react'
// import moment from 'moment'
// import { ChangeEvent, FC, useEffect, useState } from 'react'
// import { useSocialSupervision } from '../../../hook/parental-control'
// import { currentISOTime, getColorCode } from '../../../utils/helper'
// import SocialPieChart from '../charts/social-supervision-pie-chart'
// import { Box } from '@mui/material'
// import LoadingSpinner from '../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
// import SessionTimeOutModal from '../../SessionTimeOutModal'
// import { useSessionContex } from '../../../SessionContex'

// interface Props {
//   email: string
//   dataFilter: 'today' | 'thisWeek' | 'thisMonth'
//   deviceFilter: any
//   fromDate?: any
// }

// const SocialSupervisionTable: FC<Props> = ({ email, dataFilter, deviceFilter, fromDate }) => {
//   // pagination
//   const [offset, setOffset] = useState<number>(0)
//   const [limit, setLimit] = useState<number>(6)
//   const [currentPage, setCurrentPage] = useState<number>(0)
//   const [newData, setNewData] = useState<any>([])
//   const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

//   const { data, isFetching, refetch } = useSocialSupervision(fromDate, email, deviceFilter, offset, limit)

//   useEffect(() => {
//     if (data === 401) {
//       !openModal && setOpenModal(true)
//     } else {
//       if (data && data[0] && data[0]?.data) {
//         setNewData(data[0]?.data)
//       } else {
//         setNewData([])
//       }
//     }
//   }, [data])

//   useEffect(() => {
//     setCurrentPage(0)
//     setOffset(0)
//     refetch()
//   }, [dataFilter, fromDate, deviceFilter])
//   useEffect(() => {
//     refetch()
//   }, [offset, limit])

//   useEffect(() => {
//     if (currentPage === 0 || offset === 0) {
//       refetch()
//     } else {
//       setCurrentPage(0)
//       setOffset(0)
//     }
//   }, [reCall])

//   useEffect(() => {
//     if (!isFetching) {
//       if (reCall) {
//         setTimeout(() => {
//           setReCall(false)
//         }, 500)
//       }
//     }
//   }, [isFetching])

//   const handleChangePage = (event: unknown, newPage: number) => {
//     setCurrentPage(newPage)
//     const newOffset = newPage * limit
//     setOffset(newOffset)
//   }

//   const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
//     // setRowsPerPage(+event.target.value)
//     setLimit(parseInt(event.target.value))
//     setOffset(0)
//     setCurrentPage(0)
//   }

//   return (
//     <div className='bg-white table-main-wrapper'>
//       <SocialPieChart email={email} deviceFilter={deviceFilter} dataFilter={dataFilter} fromDate={fromDate} />

//       <table className='pc-table'>
//         <thead>
//           <tr>
//             <td>Allowed</td>
//             <td>Category</td>
//             <td>Website</td>
//             <td>Device Name</td>
//             <td>Time</td>
//           </tr>
//         </thead>
//         <tbody>
//           {isFetching && (
//             <tr>
//               <td colSpan={6}>
//                 <Box display={'flex'} justifyContent={'center'}>
//                   <LoadingSpinner />
//                 </Box>
//                 {/* <span className='animate-pulse mx-auto'>Loading</span> */}
//               </td>
//             </tr>
//           )}

//           {!isFetching &&
//             newData &&
//             newData.length !== 0 &&
//             newData
//               ?.sort((a: any, b: any) => {
//                 if (a.start_datetime > b.start_datetime) return -1
//                 if (a.start_datetime < b.start_datetime) return 1
//                 return 0
//               })
//               // ?.slice(offset, offset + limit)
//               .map((row: any) => {
//                 const time = moment
//                   .utc(row.start_datetime)
//                   .local()
//                   .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
//                 // const time = moment(row.start_datetime).format('D.M.YYYY hh:mm A').split(' ')

//                 return (
//                   <tr>
//                     <td>
//                       {row.is_website_blocked ? (
//                         <CloseCircle size='32' color={getColorCode('redToYellow')} variant='Bold' />
//                       ) : (
//                         <TickCircle size='32' color={getColorCode('greenToGreen')} variant='Bold' />
//                       )}
//                     </td>
//                     <td>{row.category_name || '-'}</td>
//                     <td>{row.hostname || '-'}</td>
//                     <td>{row.device_name || 'Unknown'}</td>
//                     <td>
//                       {time}
//                       {/* {time[0]}
//                       <br />
//                       {time[1] + ' ' + time[2]} */}
//                     </td>
//                   </tr>
//                 )
//               })}
//           {!isFetching && newData?.length === 0 && (
//             <tr>
//               <td colSpan={6} align='center'>
//                 <Box display={'flex'} justifyContent={'center'} textAlign={'center'} color={getColorCode('redToBlue')}>
//                   No Data found
//                 </Box>
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//       {!isFetching && newData && newData?.length !== 0 && (
//         <TablePagination
//           sx={{ marginRight: 0 }}
//           rowsPerPageOptions={[3, 6, 10, 20, 50]}
//           component='div'
//           count={(data && data[1]?.total?.split('/')[1]) || 0}
//           rowsPerPage={limit}
//           page={currentPage}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       )}
//     </div>
//   )
// }

// export default SocialSupervisionTable

import { commonCondition } from '../../../lib/utils'
import SocialSupervisionTableCommon from '../common/table/social-supervision-table'
import SocialSupervisionTableStaging from '../staging/table/social-supervision-table'

const SocialSupervisionTable: React.FC<any> = ({ email, dataFilter, deviceFilter, fromDate }) => {
  return (
    <>
      {commonCondition ? (
        <SocialSupervisionTableStaging
          email={email}
          dataFilter={dataFilter}
          deviceFilter={deviceFilter}
          fromDate={fromDate}
        />
      ) : (
        <SocialSupervisionTableCommon
          email={email}
          dataFilter={dataFilter}
          deviceFilter={deviceFilter}
          fromDate={fromDate}
        />
      )}
    </>
  )
}

export default SocialSupervisionTable
