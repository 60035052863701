import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { KEYCLOAK_CONFIG } from '../../app-config'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { notifyError } from '../../components/Toaster'

interface LoginProps {
  email: string
  password: string
}
// curl
//  --location 'https://sso.customer.io.xunison.com/auth/realms/Customer/protocol/openid-connect/token'
//  --header 'Content-Type: application/x-www-form-urlencoded'
//  --data-urlencode 'grant_type=refresh_token'
//   --data-urlencode 'client_id=Web App Client'
//   --data-urlencode 'client_secret=LavSZA8rL2Im7U9Td54OTx4blB9Wuu3m'
//   --data-urlencode 'refresh_token=eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJlM2YzMjg4MS05N2E4LTQ3ZDktOTljOC04MDJhMjk2OTk5ZDMifQ.eyJleHAiOjE3MDg3NTU5MjgsImlhdCI6MTcwNzQ2NDU5NCwianRpIjoiZmMyMjhkYmYtZDg1ZC00MGE5LWIzYWEtNDU5ZjRkYjYwN2I3IiwiaXNzIjoiaHR0cHM6Ly9zc28uY3VzdG9tZXIuaW8ueHVuaXNvbi5jb20vcmVhbG1zL0N1c3RvbWVyIiwiYXVkIjoiaHR0cHM6Ly9zc28uY3VzdG9tZXIuaW8ueHVuaXNvbi5jb20vcmVhbG1zL0N1c3RvbWVyIiwic3ViIjoiZDgyYTljODEtMmQxMy00MDMyLTkxNjAtYjgwZmQ0ZTU5NzhmIiwidHlwIjoiUmVmcmVzaCIsImF6cCI6IldlYiBBcHAgQ2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjZhZjlmZDA4LTliMDctNDc4OS04OTAwLTJlY2YwMzU3YmJlMiIsInNjb3BlIjoiY3VzdG9tX2F0dHJpYnV0ZXMgZW1haWwgcHJvZmlsZSIsInNpZCI6IjZhZjlmZDA4LTliMDctNDc4OS04OTAwLTJlY2YwMzU3YmJlMiJ9.8Z1xeMRQXMwiqpHixNI6gWmHCfDAJTOXNHEQQkRbUyU'

const login = async ({ email, password }: LoginProps) => {
  const body = new URLSearchParams()
  body.append('client_id', KEYCLOAK_CONFIG.CLIENT_ID)
  body.append('client_secret', KEYCLOAK_CONFIG.CLIENT_SECRET)
  body.append('grant_type', 'password')
  body.append('scope', 'profile')
  // body.append('refresh_token', token)
  body.append('username', email)
  body.append('password', password)

  const loginUrl = `${KEYCLOAK_CONFIG.URL}/auth/realms/${KEYCLOAK_CONFIG.REALM}/protocol/openid-connect/token`
  const response = await axios.post(loginUrl, body)

  return response.data
}

const useLogin = () => {
  return useMutation(login)
}

export default useLogin

export const sendRestPasswordMail: any = async (mail: any) => {
  const email: any = encodeURIComponent(mail)
  const headers = { 'Sec-Fetch-Site': 'cross-site' }
  try {
    const response = await axios.put(
      `https://${process.env.REACT_APP_SSO}/api/${KEYCLOAK_CONFIG.REALM}/users/execute-actions-email?email=${email}`,
      {},
      {
        headers
      }
    )
    return response.status
  } catch (error) {
    return error
  }
}

export const useForgotPassword = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(sendRestPasswordMail)

  return { mutate, isLoading, error }
}

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`

export const usePutToken = () => {
  const postRequest = async () => {
    try {
      const response = await xcpemSec.post(`${baseUrl}/rpc/put_user_token_for_device_id_from_app`, {
        _device_id: 'WebApp',
        _user_token: 'test_token'
      })
      return response.data
    } catch (error: any) {
      notifyError(`Put token: ${error?.response?.data?.message || error?.message}`)
      throw new Error(error?.response?.data?.message || error?.message)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(postRequest)

  return { mutate, isLoading, error }
}
