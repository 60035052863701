/* eslint-disable react-hooks/exhaustive-deps */
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomeTabs from '../../../../components/CustomeTabs'
import { useGatewayServices } from '../../../../hook/useGatewayServices'
import { commonCondition } from '../../../../lib/utils'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import './activity.scss'
import DeviceLog from './deviceLog'
import ParentalLogs from './parentalLog'
import SupportAgentLog from './supportAgentLog'

const tabsList = [
  {
    label: 'Device',
    testId: 'testid_device_logs',
    tab: (
      <>
        <DeviceLog />
      </>
    )
  },
  {
    label: 'Support Agent',
    testId: 'testid_support_logs',
    tab: (
      <>
        <SupportAgentLog />
      </>
    )
  }
  // {
  //   label: 'Cyber',
  //   tab: (
  //     <>
  //       <CyberLogs />
  //     </>
  //   )
  // },
  // {
  //   label: 'Parental',
  //   tab: (
  //     <>
  //       <ParentalLogs />
  //     </>
  //   )
  // }
  // {
  //   label: 'IoT',
  //   tab: (
  //     <>
  //       <IotLogs />
  //     </>
  //   )
  // }
]
const CustomersActivities = () => {
  const [tab, setTab] = useState(tabsList)
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data, refetch } = useGatewayServices(gw_uid)

  useEffect(() => {
    refetch()
  }, [gw_uid])

  useEffect(() => {
    if (commonCondition) {
      const newTab: any = {
        label: 'Parental',
        testId: 'testid_parental_logs',
        tab: (
          <>
            <ParentalLogs />
          </>
        )
      }
      const setArray = data?.srv_pc_enabled ? [...tabsList, newTab] : tabsList
      setTab(setArray)
    } else {
      setTab(tabsList)
    }
  }, [data])

  return (
    <Card className='info-card-cls activity-card '>
      <CardHeader className='info-header-cls' title='Activities' />
      <CustomeTabs tabsList={tab} divider={<Divider className='' />} className='activity-section' />
    </Card>
  )
}

export default CustomersActivities
