import * as d3 from 'd3'
import moment from 'moment'
import { Alert } from '../models/Xcpem'

export const getColorCode = (value: string): string => {
  let color = process.env.REACT_APP_COLOR === 'hitron' ? '#DC4405' : '#FF8181'
  switch (value) {
    // Telemetry History chart color
    case 'uptime':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#FFA393' : '#2CAFFE'
      break
    case 'memfree':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2c2fef' : '#544FC5'
      break
    case 'load_average':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#A3D696'
      break
    case 'freedata':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#808000' : '#006A35'
      break
    case 'wifi_temp0':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#FFA393' : '#6B8ABC'
      break
    case 'wifi_temp1':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2c2fef' : '#D568FB'
      break
    case 'wifi_temp2':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#2EE0CA'
      break
    case 'fw_version_num':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#808000' : '#FFB946'
      break
    // Telemetry History chart color over
    // other chart color
    case 'chartDarkRedToDarkPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#b91c1c'
      break
    case 'chartDarkRedToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#b91c1c'
      break
    case 'chartPurpleBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2C2FEF' : '#6C83FF'
      break
    case 'chartPurpleDarkPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#6C83FF'
      break
    case 'chartDarkGrayBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2C2FEF' : '#6B7A9F'
      break
    case 'chartRedBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2C2FEF' : '#FF8181'
      break
    case 'chartDarkRedchartBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2c2fef' : '#b91c1c'
      break
    case 'chartOrangeRed':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DC4405' : '#FFB946'
      break
    case 'chartRedPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#FFA393' : '#FFB946'
      break
    case 'chartRedDarkPink':
      color = process.env.REACT_APP_COLOR === 'hitron' ? '#B5179E' : '#FFB946'
      break
    case 'chartDarkGrayDarkPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#6B7A9F'
      break
    case 'chartPurpleGreen':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#6C83FF'
      break
    case 'chartOrangePink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#FFA393' : '#FF974C'
      break
    case 'chartGreenPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#FFA393' : '#A3D696'
      break
    case 'chartGreenBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2C2FEF' : '#A3D696'
      break
    case 'chartDarkRedToMahendi':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#808000' : '#b91c1c'
      break
    case 'chartPurpleMahendi':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#808000' : '#6C83FF'
      break
    // other chart color over
    case 'cancelBtnText':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#ffffff'
      break
    case 'cancelBtnBg':
      color = process.env.REACT_APP_COLOR === 'hitron' ? '#ffffff' : '#6c83ff'
      break
    case 'logOutBtnText':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#ffffff'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#6b7a9f'
      break
    case 'logOutBtnBg':
      color = process.env.REACT_APP_COLOR === 'hitron' ? '#004C97' : '#ffffff'
      break
    case 'orangeBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#FF974C'
      break
    case 'orangeHitBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#FF974C'
      break
    case 'redToRed':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DC4405' : '#FF8181'
      break
    case 'white':
      color = '#FFFFFF'
      break
    case 'greenDarkPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#A3D696'
      break
    case 'redCyan':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00A3E0' : '#ff8284'
      break
    case 'blackLightGreen':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#b1ffea' : '#000000'
      break
    case 'infoBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#6581fa'
      break
    case 'blueBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#0070f0'
      break
    case 'purpleBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#6C83FF'
      break
    case 'greenBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#A3D696'
      break
    case 'lightgreenBorderlightBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer'
          ? 'rgba(20, 76, 151, 0.33)'
          : '#a3d69666'
      break
    case 'greenToGreen':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#A3D696'
      break
    case 'darkGreenToGreen':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#01be4f'
      break
    case 'darkGreenToDarkPink':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#B5179E' : '#15803d'
      break
    case 'greenToCyan':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00ffff' : '#A3D696'
      break
    case 'redToCyan':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00ffff' : '#FF8181'
      break
    case 'yellowToCyan':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00ffff' : '#FF8181'
      break
    case 'orangeToOrange':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DC4405' : '#FF8181'
      break
    case 'yellowToYellow':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DAAA00' : '#F1AD2A'
      break
    case 'redToYellow':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DAAA00' : '#FF8181'
      break
    case 'redToGray':
    case 'N/A':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#737373' : '#FF8181'
      break
    case 'redToBlack':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#000000' : '#FF8181'
      break
    case 'redToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#FF8181'
      break
    case 'greenToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron'
          ? '#004C97'
          : process.env.REACT_APP_COLOR === 'customer'
          ? '#ff974c'
          : '#01be4f'
      break
    case 'orangeToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#ff974c'
      break
    case 'textGrayToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#5d6670'
      break
    case 'darkRedToBlue':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#b91c1c'
      break
    case 'lightblueToGray':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#737373' : '#B4D0FF'
      break
    case 'darkGrayToGray':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#737373' : '#6B7A9F'
      break
    case 'grayToGray':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#737373' : '#ababab'
      break
    case 'Poor':
    case 'High congestion':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DAAA00' : '#F27A7A'
      break
    case 'No issues':
    case 'gray':
      color = '#737373'
      break
    case 'Fair':
      color = '#DAAA00'
      break
    case 'Good':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#A3D696'
      break
    case 'Average':
    case 'Normal':
    case 'Info':
    case 'AllDevices':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#2C2FEF' : '#6C83FF'
      // '#6C83FF'
      break
    case 'SIG_STR_MID':
      //yellow
      color =
        // process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DAAA00' : '#FF974C'
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#ee8f00' : '#FF974C'
      break
    case 'SIG_STR_LOW':
      //red
      color =
        // process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#DC4405' : '#FF8181'
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#ad3201' : '#FF8181'
      break
    case 'SIG_STR_HIGH':
      // green
      color =
        // process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#A3D696'
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00ad56' : '#A3D696'
      break
    case 'Warning':
    case 'AllCustomer':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#004C97' : '#FF974C'
      break
    case 'Critical':
    case 'Open':
    case 'Offline':
      // color = process.env.REACT_APP_COLOR === 'hitron' ? '#DC4405' : '#FF8181'
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#737373' : '#FF8181'
      break

    case 'Closed':
    case 'Online':
      color =
        process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? '#00CC66' : '#A3D696'
      break
    case 'Neutral':
      color = '#EFF2FC'
      break
    case 'Text on Surface':
    case 'needUpdate':
      color = '#6B7A9F'
      break
    case 'notRegistered':
      color = 'rgb(206,223,252)'
      break
    case 'CellularUsage':
      color = '#2C2FEF'
      break
    case 'DeviceUsage':
      color = '#FF5C00'
      break
    case 'DownloadUsage':
      color = '#2C2FEF'
      break
    case 'UploadUsage':
      color = '#FF5C00'
      break
  }
  return color
}

export const getFilterTypeName = (value: string): string => {
  let name = 'Today'

  switch (value) {
    case 'today':
      name = 'Today'
      break

    case 'thisMonth':
      name = 'This Month'
      break

    case 'thisWeek':
      name = 'This Week'
      break
  }
  return name
}

export const getAlertType = (alert: string) => {
  let alertType = 'Info'
  switch (alert) {
    case 'Telemetry gap detected':
    case 'wifi_temp0 high':
    case 'wifi_temp1 high':
      alertType = 'Warning'
      break
    case 'Load average high':
      alertType = 'Critical'
      break
    case 'Free system memory low Router':
    case 'Smart device gone offline':
      alertType = 'Info'
      break
  }
  return alertType
}

export const generateArray = (n: any) => {
  const result = []
  let currentArray = []

  for (let i = 1; i <= n; i++) {
    currentArray.push(i)

    if (i % 2 === 0 || i === n) {
      result.push(currentArray)
      currentArray = []
    }
  }

  return result
}

export const generateSubArrays = (start: any, end: any, chunkSize: any) => {
  const result = []

  for (let i = start; i <= end; i += chunkSize) {
    const subarrayStart = i
    const subarrayEnd = Math.min(i + chunkSize - 1, end)
    result.push([subarrayStart, subarrayEnd])
  }

  return result
}

// export const transformProblems = (data: Problem[]) => {
//   const category: any[] = []

//   data.map((problem: Problem) => {
//     const alertType = problem.alert_type.includes('offline') ? problem.alert_desc : problem.alert_type
//     if (!category.includes(alertType)) {
//       category.push(alertType)
//     }
//   })

//   const cleanedData = category.map((item: string) => {
//     const problems = data.filter((prob: Problem) => prob.alert_type === item || prob.alert_desc === item)
//     return {
//       alert: item,
//       category: item.includes('offline') ? 'IoT' : 'Router',
//       problems: problems,
//       count: problems.length,
//       last_incident: problems[0].last_occurence,
//       alertType: getAlertType(item)
//     }
//   })

//   return cleanedData
// }

// export const getTimeRange = (type: string) => {
export const getTimeRange = (ts: string) => {
  const currentTimestamp = moment()

  function formatTimestamp(timestamp: any) {
    return moment(timestamp).format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)
  }

  const startOfMonth = currentTimestamp.clone().startOf('month')
  const now = moment()
  const startOfDay = currentTimestamp.clone().startOf('day')
  const type: string = ''

  switch (type) {
    case 'This Week':
    case 'thisWeek':
      const startOfWeek = currentTimestamp.clone().isoWeekday(1).startOf('day')
      const endOfWeek = currentTimestamp.clone().isoWeekday(7).endOf('day')
      return {
        start: formatTimestamp(startOfWeek.format()),
        end: formatTimestamp(endOfWeek.format())
      }

    case 'live':
    case 'Today':
    case 'today':
      return {
        start: formatTimestamp(startOfDay.format()),
        end: formatTimestamp(currentTimestamp.format())
      }

    case 'This Month':
    case 'thisMonth':
      return {
        start: formatTimestamp(startOfMonth.format()),
        end: formatTimestamp(currentTimestamp.format())
      }

    case 'This Quarter':
    case 'thisQuarter':
      return {
        start: formatTimestamp(now.startOf('quarter').format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)),
        end: formatTimestamp(now.endOf('quarter').format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`))
      }
    case 'This Year':
    case 'thisYear':
      return {
        start: formatTimestamp(now.startOf('year').format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)),
        end: formatTimestamp(currentTimestamp.format())
      }

    default:
      return {
        start: formatTimestamp(startOfDay.format()),
        end: formatTimestamp(currentTimestamp.format())
      }
  }
}

export const transformFilteredDevicesToWeeklyChartData = (filteredSet: any, filterData: string) => {
  if (filterData === 'This Month') {
    const groupedObjects: { [key: string]: any[] } = {
      W1: [],
      W2: [],
      W3: [],
      W4: [],
      W5: []
    }

    filteredSet.forEach((obj: { last_seen: moment.MomentInput }) => {
      const filterDate: any = moment(obj.last_seen)
      const weekOfMonth = filterDate.week() - moment(filterDate).startOf('month').week() + 1
      groupedObjects[`W${weekOfMonth}`].push(obj)
    })

    return [
      groupedObjects['W1'].length,
      groupedObjects['W2'].length,
      groupedObjects['W3'].length,
      groupedObjects['W4'].length,
      groupedObjects['W5'].length
    ]
  } else {
    const groupedObjects: { [key: string]: any[] } = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: []
    }

    filteredSet.forEach((obj: { last_seen: moment.MomentInput }) => {
      const lastSeenDate = moment(obj.last_seen, `${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)
      // const dayOfWeek = lastSeenDate.format('dddd').charAt(0) // Get the first character of the day name
      const dayOfWeek = lastSeenDate.format('dddd')

      groupedObjects[dayOfWeek].push(obj)
    })
    return [
      groupedObjects['Monday'].length,
      groupedObjects['Tuesday'].length,
      groupedObjects['Wednesday'].length,
      groupedObjects['Thursday'].length,
      groupedObjects['Friday'].length,
      groupedObjects['Saturday'].length,
      groupedObjects['Sunday'].length
    ]
  }
}

export const DateFilterLabels = {
  today: 'Today',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  live: 'live',
  thisQuarter: 'This Quarter',
  thisYear: 'This Year'
}
export function getDateRange(dataFilter: 'today' | 'thisWeek' | 'thisMonth' | 'thisYear') {
  let start_time, end_time
  const today = moment().startOf('day')

  if (dataFilter === 'today') {
    start_time = today.format('YYYYMMDDTHH:mm:ss')
    end_time = moment(today).endOf('day').format('YYYYMMDDTHH:mm:ss')
  } else if (dataFilter === 'thisWeek') {
    start_time = moment(today).startOf('week').format('YYYYMMDDTHH:mm:ss')
    end_time = moment(today).endOf('week').format('YYYYMMDDTHH:mm:ss')
  } else if (dataFilter === 'thisMonth') {
    start_time = moment(today).startOf('month').format('YYYYMMDDTHH:mm:ss')
    end_time = moment(today).endOf('month').format('YYYYMMDDTHH:mm:ss')
  } else {
    // Invalid dataFilter value, handle accordingly
    start_time = today.format('YYYYMMDDTHH:mm:ss')
    end_time = moment(today).endOf('day').format('YYYYMMDDTHH:mm:ss')
  }

  return { start_time, end_time }
}

export const transformToCamelCase = (str: string) => {
  // Remove special characters and split the string into an array of words
  const words = str.replace(/[^a-zA-Z0-9]/g, ' ').split(' ')

  // Capitalize the first letter of each word except the first word
  const camelCasedWords = words.map((word, index) =>
    index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
  )

  // Join the words together
  const camelCasedString = camelCasedWords.join('')

  return camelCasedString
}

// device classifier

export const getImageByDeviceTypeOrDeviceNameOrHostName = (classifier: {
  device_type: string
  device_name: string
  host: string
}) => {
  //   router

  // Xu router
  // router model

  // third party router
  // generic image

  if (
    classifier.device_name.toLowerCase().includes('storage') ||
    classifier.device_type.toLowerCase().includes('storage') ||
    classifier.host.toLowerCase().includes('storage')
  ) {
    return 'storage'
  }

  if (
    classifier.device_name.toLowerCase().includes('switches') ||
    classifier.device_type.toLowerCase().includes('switches') ||
    classifier.host.toLowerCase().includes('switches')
  ) {
    return 'network-switch'
  }

  if (
    classifier.device_name.toLowerCase().includes('xunison') ||
    classifier.device_type.toLowerCase().includes('xunison') ||
    classifier.host.toLowerCase().includes('xunison')
  ) {
    return 'd50-home'
  }

  if (
    classifier.device_name.toLowerCase().includes('other iot') ||
    classifier.device_type.toLowerCase().includes('other iot') ||
    classifier.host.toLowerCase().includes('other iot')
  ) {
    return 'other-iot'
  }

  if (
    classifier.device_name.toLowerCase().includes('iphone') ||
    classifier.device_type.toLowerCase().includes('iphone') ||
    classifier.host.toLowerCase().includes('iphone')
  ) {
    return 'iphone'
  }

  if (
    classifier.device_name.toLowerCase().includes('android') ||
    classifier.device_type.toLowerCase().includes('android') ||
    classifier.host.toLowerCase().includes('android')
  ) {
    return 'android'
  }

  if (
    classifier.device_name.toLowerCase().includes('operating system') ||
    classifier.device_type.toLowerCase().includes('apple os')
  ) {
    return 'macbook'
  }

  if (
    classifier.device_name.toLowerCase().includes('operating system') ||
    classifier.device_type.toLowerCase().includes('windows') ||
    classifier.host.toLowerCase().includes('windows')
  ) {
    return 'windows'
  }

  if (classifier.device_type === null && classifier.device_name === null && classifier.host === null) {
    return 'unknown'
  }

  return 'unknown'
}

export const groupDataByTimestamp = (data: any[], filterDuration: string) => {
  let startDate = moment()
  let endDate = moment()

  // Set the start and end dates based on the filter duration
  if (filterDuration === 'today') {
    startDate.startOf('day')
    endDate.endOf('day')
  } else if (filterDuration === 'thisWeek') {
    startDate.startOf('isoWeek')
    endDate.endOf('isoWeek')
  } else if (filterDuration === 'thisMonth') {
    startDate.startOf('month')
    endDate.endOf('month')
  }

  const groupedData: any = {}

  // Initialize the groupedData object with default values
  moment.weekdays().forEach(day => {
    groupedData[day] = {
      Warning: 0,
      Critical: 0,
      Info: 0
    }
  })

  // Group the data based on the timestamp
  data.forEach(item => {
    const timestamp = moment(item.timestamp)
    if (timestamp.isBetween(startDate, endDate)) {
      const alertType = getAlertType(item.alert_type)
      const dayOfWeek = timestamp.format('dddd')
      groupedData[dayOfWeek][alertType]++
    }
  })

  return groupedData
}

export const getHeatmapDataHelper = (data: any, filterType: any) => {
  const startDate = moment().startOf('day')
  const endDate = moment().endOf('day')
  // Set the start and end dates based on the filter duration
  if (filterType === 'today') {
    startDate.startOf('day')
    endDate.endOf('day')
  } else if (filterType === 'thisWeek') {
    startDate.startOf('isoWeek')
    endDate.endOf('isoWeek')
  } else if (filterType === 'thisMonth') {
    startDate.startOf('month')
    endDate.endOf('month')
  }
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  const series = []
  for (let i = 0; i < 7; i++) {
    const day = days[i]
    const filteredData = data.filter((item: any) => {
      const timestamp = moment(item.timestamp)
      return timestamp.isBetween(startDate, endDate, null, '[]') && timestamp.day() === i
    })
    const dataPoints = Array.from({ length: 24 }, (_, hour) => {
      const hourString = hour.toString().padStart(2, '0')
      const filteredHourData = filteredData.filter((item: any) => {
        const hourValue = moment(item.timestamp).hour()
        return hourValue === hour
      })
      const value = filteredHourData.length > 0 ? filteredHourData.length : 0
      const alertType = filteredHourData.length > 0 ? getAlertType(filteredHourData[0].alert_type) : 'Info'

      return [hourString, value, alertType]
    })
    series.push({
      name: day,
      data: dataPoints
    })
  }

  return series
}
export const filterAlerts = (data: any, filterType: any) => {
  let filteredData = []
  const currentDate = moment()
  if (filterType === 'today') {
    filteredData = data.filter((item: any) => moment(item.timestamp).isSame(currentDate, 'day'))
  } else if (filterType === 'thisWeek') {
    const startOfWeek = moment(currentDate).startOf('week')
    const endOfWeek = moment(currentDate).endOf('week')

    filteredData = data.filter((item: any) => moment(item.timestamp).isBetween(startOfWeek, endOfWeek, null, '[]'))
  } else if (filterType === 'thisMonth') {
    const startOfMonth = moment(currentDate).startOf('month')
    const endOfMonth = moment(currentDate).endOf('month')
    filteredData = data.filter((item: any) => moment(item.timestamp).isBetween(startOfMonth, endOfMonth, null, '[]'))
  }
  return filteredData
}

// export const transformToCamelCase = (str: string) => {
//   // Remove special characters and split the string into an array of words
//   const words = str.replace(/[^a-zA-Z0-9]/g, ' ').split(' ')

//   // Capitalize the first letter of each word except the first word
//   const camelCasedWords = words.map((word, index) =>
//     index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
//   )

//   // Join the words together
//   const camelCasedString = camelCasedWords.join('')

//   return camelCasedString
// }
// mqtt helpers

export const generatePayload = (config: any) => {
  return {
    WLAN_SSID_2G: config.ssid,
    WLAN_SSID_5G: config.ssid,
    WLAN_password_2G: config.password,
    WLAN_password_5G: config.password
  }
}

export const siteSurvey = JSON.stringify({
  cmd: 'site_survey',
  callid: 333,
  response_topic: 'site_survey',
  op: 'wizard'
})

export const message = JSON.stringify({
  cmd: 'getsysconfig',
  callid: 3,
  response_topic: 'resp',
  op: 'wizard'
})

export const getColorCodesByRssi = (value: number) => {
  let color = process.env.REACT_APP_COLOR === 'hitron' ? '#00CC66' : '#A3D696'

  if (value >= -30 && value <= -60) {
    color = process.env.REACT_APP_COLOR === 'hitron' ? '#00CC66' : '#A3D696'
  } else if (value > -60 && value <= -70) {
    color = '#FF974C'
  } else if (value < -70) {
    color = process.env.REACT_APP_COLOR === 'hitron' ? '#DC4405' : '#FF8181'
  }
  return color
}

export const generateRandomQoeScore = () => {
  var min = 1
  var max = 5
  var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
  return randomNumber
}

export const getImageByDeviceTypeOrDeviceName = (classifier: {
  device_type: string
  device_name: string
  host: string
}) => {
  //   router

  // Xu router
  // router model

  // third party router
  // generic image

  if (!!classifier.device_name && !!classifier.device_type && !!classifier.host) {
    if (
      classifier.device_name.toLowerCase().includes('storage') ||
      classifier.device_type.toLowerCase().includes('storage') ||
      classifier.host.toLowerCase().includes('storage')
    ) {
      return 'storage'
    }

    if (
      classifier.device_name.toLowerCase().includes('switches') ||
      classifier.device_type.toLowerCase().includes('switches') ||
      classifier.host.toLowerCase().includes('switches')
    ) {
      return 'network-switch'
    }

    if (
      classifier.device_name.toLowerCase().includes('xunison') ||
      classifier.device_type.toLowerCase().includes('xunison') ||
      classifier.host.toLowerCase().includes('xunison')
    ) {
      return 'hub'
    }

    if (
      classifier.device_name.toLowerCase().includes('other iot') ||
      classifier.device_type.toLowerCase().includes('other iot') ||
      classifier.host.toLowerCase().includes('other iot')
    ) {
      return 'other-iot'
    }

    if (
      classifier.device_name.toLowerCase().includes('iphone') ||
      classifier.device_type.toLowerCase().includes('iphone') ||
      classifier.host.toLowerCase().includes('iphone')
    ) {
      return 'iphone'
    }

    if (
      classifier.device_name.toLowerCase().includes('android') ||
      classifier.device_type.toLowerCase().includes('android') ||
      classifier.host.toLowerCase().includes('android')
    ) {
      return 'android'
    }

    if (
      classifier.device_name.toLowerCase().includes('operating system') ||
      classifier.device_type.toLowerCase().includes('apple os')
    ) {
      return 'macbook'
    }

    if (
      classifier.device_name.toLowerCase().includes('operating system') ||
      classifier.device_type.toLowerCase().includes('windows') ||
      classifier.host.toLowerCase().includes('windows')
    ) {
      return 'windows'
    }
  }

  if (classifier.device_type === null && classifier.device_name === null && classifier.host === null) {
    return 'unknown'
  }

  return 'macbook'
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getRandomQoEScore = () => {
  const values = [1, 2, 3, 4, 5]
  const randomIndex = Math.floor(Math.random() * values.length)
  const randomValue = values[randomIndex]
  return randomValue
}

export const calculateTotalSum = (data: any) => {
  let totalSum = 0

  for (const day in data) {
    const { Warning, Critical, Info } = data[day]
    totalSum += Warning + Critical + Info
  }
  return totalSum
}

export const getCurrentYearMonths = () => {
  const currentDate = moment()
  const currentYear = currentDate.year()
  const monthsArray = []

  for (let month = 0; month < currentDate.month() + 1; month++) {
    const monthString = moment().year(currentYear).month(month).format('MMM')
    monthsArray.push(monthString)
  }

  return monthsArray
}

export const getCurrentQuarterMonths = () => {
  const currentQuarter = moment().quarter()
  const startMonth = (currentQuarter - 1) * 3
  const monthsArray = []
  for (let month = startMonth; month < startMonth + 3; month++) {
    const monthString = moment().month(month).format('MMM')
    monthsArray.push(monthString)
  }
  return monthsArray
}

export const getCurrentWeekDays = () => {
  const today = moment()
  const startOfWeek = today.clone().startOf('isoWeek')

  const weekdays = []
  for (let i = 0; i < 7; i++) {
    const weekday = startOfWeek.clone().add(i, 'days')
    weekdays.push(weekday.format('dd'))
  }

  return weekdays
}

export const getAlertCategories = () => {
  return ['Critical', 'Warning', 'Info']
}

export const generateData = (min: number, max: number, n: number) => {
  const data = []
  const step = (max - min) / (n - 1)

  for (let i = 0; i < n; i++) {
    const value = Math.ceil(min + step * i * Math.random())
    data.push(value)
  }

  return data
}

export const getSumOfArrayOfNumbers = (data: number[]) => {
  return data.reduce((sum, number) => sum + number, 0)
}

export const transformToupTime = (value: number): string => {
  return moment.utc(value * 1000).format('DD\\d HH\\h mm\\m ss\\s')
}

export const getStatusColorBySanity = (score: number) => {
  let colorScheme = getColorCode('Critical')

  if (score < 60) colorScheme = getColorCode('Critical')
  if (score < 80 && score >= 60) colorScheme = getColorCode('Warning')
  if (score >= 80) colorScheme = getColorCode('Closed')

  return colorScheme
}

export const getActiveNav = (title: string, path: string) => {
  const lowerCasedTitle = title.toLowerCase()

  switch (true) {
    case lowerCasedTitle === 'dashboard' && path === '/':
      return true
    case lowerCasedTitle === 'reports' && path === '/reports':
      return true
    case lowerCasedTitle === 'customers' && path.startsWith('/customers'):
      return true
    case lowerCasedTitle === 'alerts' && path === '/alerts':
      return true
    default:
      return false
  }
}

export const getParentalActiveNav = (title: string, path: string) => {
  const lowerCasedTitle = title.toLowerCase()

  switch (true) {
    case lowerCasedTitle === 'dashboard' && (path.startsWith('/parental-control/') || path.startsWith('/smart-home')):
      return true
    case lowerCasedTitle === 'devices' && path.startsWith('/devices'):
      return true
    case lowerCasedTitle === 'dashboard' && path.startsWith('/cyber-security/'):
      return true
    case lowerCasedTitle === 'threats' && path.startsWith('/cyber-security-threats/'):
      return true
    case lowerCasedTitle === 'supervision' && path.startsWith('/parental-control-supervision'):
      return true
    case lowerCasedTitle === 'policy' && path.startsWith('/parental-control-policy'):
      return true
    case lowerCasedTitle === 'settings' && path.startsWith('/parental-control-settings'):
      return true
    default:
      return false
  }
}

export const getFirstTwoLetters = (sentence: string) => {
  const words = sentence.split(' ') // Split the sentence into individual words
  const firstTwoLetters = words.map(word => word.substring(0, 1).toUpperCase()) // Get the first two letters of each word
  return firstTwoLetters
}

export const getWeekValues = () => {
  const currentDate = moment()
  const weeksInMonth = moment(currentDate).endOf('month').isoWeek()
  const weekValues = []

  for (let week = 1; week <= weeksInMonth; week++) {
    const weekStart = moment(currentDate).startOf('month').isoWeek(week)
    const weekEnd = moment(currentDate).endOf('month').isoWeek(week)
    const weekData = []

    let day = moment(weekStart) // Initialize day as a moment object

    while (day <= weekEnd) {
      weekData.push(day.date()) // Change this line to return the desired value for each day
      day.add(1, 'day') // Increment day using the add() method
    }

    weekValues.push(parseInt((Math.random() * 10).toFixed()))
  }

  return weekValues
}

export const getDayNamesOfMonth = (fullValue: boolean = false) => {
  const currentDate = moment()
  const daysInMonth = moment(currentDate).endOf('month').date()
  const dayNames = []

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDay = moment(currentDate).date(day)
    const dayName = fullValue ? currentDay.format('dddd') : currentDay.format('dd').charAt(0)

    dayNames.push(dayName)
  }

  return dayNames
}

export const RenameDeviceManufacturer = (manufacturer: string, iot: boolean = false, bySerial: boolean = false) => {
  const manufacturersToBeReplaced = ['Turris Omnia', 'Wallys DR6018 V4']

  const iotdevices = ['NVIDIA Jetson Nano Developer Kit']

  const serialNumbers = ['d4f33704b147']

  if (iot) {
    return iotdevices.includes(manufacturer) ? 'Xunison IOT' : manufacturer
  }

  if (bySerial) {
    return serialNumbers.includes(manufacturer) ? 'Xunison Q50' : manufacturer
  }

  if (!iot && !bySerial) {
    return manufacturersToBeReplaced.includes(manufacturer) ? 'Xunison D60-5G-X2' : manufacturer
  }
}

export const transformProblems = (data: Alert[]) => {
  const category: any[] = []
  if (data.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.map((problem: Alert) => {
      const alertType = problem.alert_type.includes('offline') ? problem.alert_desc : problem.alert_type
      if (!category.includes(alertType)) {
        category.push(alertType)
      }
    })
  }

  const cleanedData = category.map((item: string) => {
    const problems = data.filter((prob: Alert) => prob.alert_type === item || prob.alert_desc === item)
    return {
      alert: item,
      category: item.includes('offline') ? 'IoT' : 'Router',
      problems: problems,
      count: problems.length,
      last_incident: problems[0].last_occurence,
      alertType: getAlertType(item)
    }
  })

  return cleanedData
}

export const generateCoordinates = (
  requiredNodes: number,
  canvasWidth: number,
  canvasHeight: number,
  isSatellite: boolean,
  sourceX: number,
  sourceY: number
) => {

  const radiusThreshold = isSatellite ? 0.55 : 0.3

  const radius = Math.min(canvasWidth, canvasHeight) * radiusThreshold // Adjust the radius as needed
  const separationAngle = (2 * Math.PI) / requiredNodes // Angle between nodes

  const offset = isSatellite ? 20 : 0
  // Create an array of nodes with calculated coordinates
  const nodes = d3
    .range(0, 2 * Math.PI, separationAngle)
    .map((angle: any) => [sourceX + radius * Math.cos(angle + offset), sourceY + radius * Math.sin(angle + offset)])

  return nodes
}

export const getRadialCoordinates = (numPoints: number, radius: number, sourceX: number, sourceY: number) => {
  let nodes = []

  let radiusOffset = 0

  for (let i = 0; i < numPoints; i++) {
    // if(numPoints > 10 ){
    //    radiusOffset = Math.random() < 0.5 ? 0 : 150;
    // }

    const angle = (i / numPoints) * 2 * Math.PI // Calculate the angle
    const x = sourceX + (radius + radiusOffset) * Math.cos(angle) // Calculate the x-coordinate
    const y = sourceY + (radius + radiusOffset) * Math.sin(angle) // Calculate the y-coordinate
    nodes.push({ x: x, y: y })
  }

  return nodes
}

// export const getRadialCoordinates = (numPoints: number, radius: number, yOffset: number, sourceX: number, sourceY: number) => {
//   let nodes = [];
//
//   if (numPoints <= 12) {
//     for (let i = 0; i < numPoints; i++) {
//       const angle = (i / numPoints) * 2 * Math.PI;
//       const x = sourceX + radius * Math.cos(angle);
//       const y = sourceY + radius * Math.sin(angle);
//       nodes.push({ x: x, y: y });
//     }
//   } else {
//     const numPointsAtRadiusX = Math.ceil(numPoints / 2);
//     const numPointsAtRadiusY = numPoints - numPointsAtRadiusX;
//
//     for (let i = 0; i < numPointsAtRadiusX; i++) {
//       const angle = (i / numPointsAtRadiusX) * Math.PI; // Using half the angle range
//       const x = sourceX + radius * Math.cos(angle);
//       const y = sourceY + radius * Math.sin(angle);
//       nodes.push({ x: x, y: y });
//     }
//
//     for (let i = 0; i < numPointsAtRadiusY; i++) {
//       const angle = (i / numPointsAtRadiusY) * Math.PI; // Using half the angle range
//       const x = sourceX + (radius + yOffset) * Math.cos(angle);
//       const y = sourceY + (radius + yOffset) * Math.sin(angle);
//       nodes.push({ x: x, y: y });
//     }
//   }
//
//   return nodes;
// };

export const generateSquareNodes = () => {
  const canvasWidth = 800
  const canvasHeight = 800
  const nodeSize = 40
  const clearanceRadius = 40

  const effectiveNodeSize = nodeSize + 2 * clearanceRadius // Account for clearance

  const rows = Math.floor(canvasHeight / effectiveNodeSize)
  const columns = Math.floor(canvasWidth / effectiveNodeSize)

  const nodeCoordinates = []

  for (let row = 0; row < rows; row++) {
    for (let column = 0; column < columns; column++) {
      const nodeX = column * effectiveNodeSize + clearanceRadius
      const nodeY = row * effectiveNodeSize + clearanceRadius

      nodeCoordinates.push({ x: nodeX, y: nodeY })
    }
  }
  // Print or use nodeCoordinates for drawing on the canvas
  return nodeCoordinates
}

export const getForcedLayoutCoordinates = (requiredNodes: number, canvasWidth: number, canvasHeight: number) => {}

//
// export const generateCoordinates = (requiredNodes: number, canvasWidth: number, canvasHeight: number,specialPods:number) => {
//   const center = { x: canvasWidth / 2, y: canvasHeight / 2 }
//
//   const radiusThreshold = requiredNodes >= 12 ? 0.4 : 0.3
//
//   const minSeparationAngle = 2 * Math.PI / requiredNodes // Minimum angle between nodes
//
//   const radius = Math.min(canvasWidth, canvasHeight) * radiusThreshold // Adjust the radius as needed
//   const separationAngle = Math.max(
//       minSeparationAngle,
//       (2 * Math.PI) / (requiredNodes + 3) // Increase by 3 to account for specialPods
//   )
//
//   // Create an array of nodes with calculated coordinates
//   const nodes = d3.range(0, 2 * Math.PI, separationAngle).map(angle => ({
//     x: center.x + radius * Math.cos(angle),
//     y: center.y + radius * Math.sin(angle),
//     isSpecial: false, // Set this to true for specialPods
//   }));
//
//   // Add specialPods
//   // const specialPodsCount = 3; // Number of specialPods
//   const specialPodsRadius = radius * 3; // 3X the distance
//
//   for (let i = 0; i < specialPods; i++) {
//     const angle = (2 * Math.PI * i) / specialPods;
//     nodes.push({
//       x: center.x + specialPodsRadius * Math.cos(angle),
//       y: center.y + specialPodsRadius * Math.sin(angle),
//       isSpecial: true,
//     });
//   }
//
//   // Adjust spacing to avoid overlap
//   const spacing = 20; // Adjust spacing as needed
//
//   nodes.forEach(node => {
//     for (const otherNode of nodes) {
//       if (node !== otherNode) {
//         const distance = Math.sqrt((node.x - otherNode.x) ** 2 + (node.y - otherNode.y) ** 2);
//         if (distance < spacing) {
//           const angle = Math.atan2(node.y - otherNode.y, node.x - otherNode.x);
//           node.x += spacing * Math.cos(angle);
//           node.y += spacing * Math.sin(angle);
//         }
//       }
//     }
//   });
//
//   return nodes;
// };

export const classifyWifiBand = (channel: any) => {
  let band = 2.4
  if (channel !== null) {
    if (channel >= 1 && channel <= 31) {
      band = 2.4
    } else {
      band = 5
    }
  } else {
    band = 0
  }
  return band
}

export const gradeNetworkQuality = (rssi: any) => {
  if (rssi !== null) {
    if (rssi < 50) {
      return 'Good'
    } else if (rssi >= 50 && rssi < 60) {
      return 'Warning'
    } else if (rssi >= 60) {
      return 'Bad'
    }
  } else {
    return 'Bad'
  }
}

export function convertToTitleCase(str: string) {
  const result = str.split('&')[0]
  return result.replace(/([A-Z])/g, ' $1').replace(/^./, function (s) {
    return s.toUpperCase()
  })
}

export function convertToCamelCase(str: string) {
  const result = str.split('&')[0]
  return result.replace(/([A-Z])/g, ' $1').replace(/^./, function (s) {
    return s.toUpperCase()
  })
}

export function convertCamelCaseToSentence(input: string) {
  let wordsArray = input.split(/(?=[A-Z])/)

  const data = wordsArray.map((data, index) => {
    return index === 0 ? data[0].toUpperCase() + data.substring(1).toLowerCase() : data.toLowerCase()
  })
  let outputString = data.join(' ')

  return outputString
}

export function sumArrayValues(arr: any, value: any) {
  let sum = 0

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i][value]
  }
  return sum
}

export function getDayNameFirstCharacterOfDate(inputDate: string) {
  const date = new Date(inputDate)

  const dayNameFull = date.toLocaleDateString('en-US', { weekday: 'long' })
  const firstCharacter = dayNameFull.slice(0, 3)
  return firstCharacter
}

export const currentISOTime = () => {
  let today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setSeconds(today.getSeconds() - 1)

  return today.toISOString()
}

export const monthToText = (month: number): string => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return monthNames[month - 1]
}

const getStartOfDay = (date: Date) => {
  const startOfDay = new Date(date)
  startOfDay.setHours(0, 0, 0, 0)
  return startOfDay
}

// Function to get the starting time of the current month
export const startOfMonth = () => {
  const today = new Date()
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  return getStartOfDay(startOfMonth).toISOString()
}

// Function to get the starting time of the current week (considering Sunday as the first day)
export const startOfWeek = () => {
  const today = new Date()
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
  return getStartOfDay(startOfWeek).toISOString()
}

export const cleanCurrentGatewayInfoFromSessionStorage = () => {
  localStorage.setItem('email', '')
  localStorage.setItem('mac', '')
  localStorage.setItem('gwuid', '')
}

export const convertScannedTrafficToReadableUnits = (bytes: number, decimals: number = 1) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const currentdataForChart = (result: any) => {
  const startTime = new Date()
  startTime.setHours(18, 30, 31, 0)
  const endTime = new Date(startTime.getTime() + 17 * 60 * 1000)
  const currentData = result.filter((data: { timestamp: string | number | Date }) => {
    const timestamp = new Date(data.timestamp)
    return timestamp >= startTime && timestamp <= endTime
  })
  return currentData
}

export const formatTime = (date: any) => {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export const rateScoreCalculation = (rate: any) => {
  if (rate > 750) {
    return 95
  } else {
    return 85
  }
}

export const rssiScoreCalculation = (rssi: any) => {
  if (rssi >= -30 && rssi <= -60) {
    return 95
  } else if (rssi >= -61 && rssi <= -79) {
    return 90
  } else {
    return 85
  }
}

export const bandwidthScoreCalculation = (bandwidth: any) => {
  if (bandwidth <= '20') {
    return 87
  } else if (bandwidth >= '21' && bandwidth <= '40') {
    return 90
  } else if (bandwidth >= '41' && bandwidth <= '60') {
    return 91
  } else if (bandwidth >= '61' && bandwidth <= '80') {
    return 92
  } else if (bandwidth >= '81' && bandwidth <= '120') {
    return 93
  } else {
    return 94
  }
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const generateRandomValue = () => {
  const randomDecimal = Math.random() // Generates a random number between 0 and 1
  const range = [0, 0.5, 1, 1.5, 2, 2.5]
  const randomIndex = Math.floor(randomDecimal * range.length)
  return range[randomIndex]
}
export const generateRandomData = () => {
  const data = []
  for (let i = 0; i < 17; i++) {
    // Generating 10 data points, you can adjust this number
    data.push([
      Date.UTC(2023, 8, 28, 10, 39 + i), // Increment time for each data point
      generateRandomValue()
    ])
  }
  return data
}
export const generatepacketRandomData = () => {
  const data = []
  for (let i = 0; i < 17; i++) {
    const randomTimestamp = Date.UTC(2023, 8, 28, 10, 39 + i) // Increase by one minute each time
    const randomValue = Math.random() * 10 // Generate random value between 0 and 10
    data.push([randomTimestamp, randomValue])
  }

  return data
}

export const generatePotenshialRandomData = () => {
  const data = []
  for (let i = 0; i < 17; i++) {
    const randomTimestamp = Date.UTC(2023, 8, 28, 10, 39 + i) // Increase by one minute each time
    const randomValue = Math.random() * 500 // Generate random value between 0 and 500
    data.push([randomTimestamp, randomValue])
  }
  return data
}

export const getUpTime = (value: number) => {
  let hours = Math.floor(value / 3600)
  let minutes = Math.floor((value - hours * 3600) / 60)
  let seconds = value - hours * 3600 - minutes * 60
  // round seconds
  seconds = Math.round(seconds * 100) / 100
  let result = (hours < 10 ? '0' + hours : hours) + 'h'
  result += ' ' + (minutes < 10 ? '0' + minutes : minutes) + 'm'
  result += ' ' + (seconds < 10 ? '0' + seconds : seconds) + 's'
  return result
}

export const getRouterNameByModel = (model: string) => {
  /*
  m50-mtk
  q50-ipq5
  d50-d50
  for q30 & v2 you can show q50 for now
  */
  switch (model.toLowerCase()) {
    case 'mtk':
      return 'm50'
    case 'ipq5':
    case 'q30':
      return 'q50'
    case 'd50':
      return 'd50-home'
    case 'aria':
    case 'aria2210':
      return 'aria'
    default:
      return 'd50-home'
  }
}

export const classifyWiFiType = (mode: string) => {
  if (mode === '11AC') {
    return 'WiFi5'
  }

  if (['11AXA', '11AXG'].includes(mode)) {
    return 'WiFi6'
  }

  if (['11BEA', '11BEG'].includes(mode)) {
    return 'WiFi7'
  }

  if (['11A', '11B', '11G', 'FH', 'TURBO', '11NA', '11NG', 'unknown'].includes(mode)) {
    return 'WiFi4'
  }

  return null
}
