import { Box, Skeleton, Tooltip, Typography } from '@mui/material'
import { Edit2, Trash } from 'iconsax-react'
import React from 'react'
import { UserState } from '../../redux/deviceStatistics/types'

interface MSOInfoProps {
  isFetching?: boolean
  isLoading?: boolean
  data?: any
  defaultUserData?: UserState
  setDeleteMSOModal?: any
  setEdit?: any
  setCustomerOpenModal?: any
  setEditData?: any
}

const MSOInfo: React.FC<MSOInfoProps> = ({
  isFetching,
  isLoading,
  data,
  defaultUserData,
  setDeleteMSOModal,
  setEdit,
  setCustomerOpenModal,
  setEditData
}) => {
  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          {isFetching || isLoading ? (
            <Skeleton variant='text' width={'50px'} />
          ) : (
            <>
              <b>{data?.white_label || data?.customer_name || '-'}</b>
              {!defaultUserData?.isMSOPortalAdmin && (
                <>
                  &nbsp; &nbsp;
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                        }
                      }
                    }}
                    title={<Typography>Edit MSO</Typography>}
                  >
                    <Edit2
                      size={'18px'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCustomerOpenModal(true)
                        setEdit(true)
                        setEditData(data)
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {defaultUserData?.isPlatformSuperAdmin && (
                <>
                  &nbsp; &nbsp;
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                        }
                      }
                    }}
                    title={<Typography>Delete MSO</Typography>}
                  >
                    <Trash
                      size={'18px'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setDeleteMSOModal(true)
                        setEditData(data)
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          )}
        </Box>
        <Box display={'flex'}>
          {isFetching || isLoading ? <Skeleton variant='text' width={'80%'} /> : <>{data?.customer_address}</> || '-'}
        </Box>
        <Box>
          {isFetching || isLoading ? (
            <Skeleton variant='text' width={'80%'} />
          ) : (
            <>
              {data?.customer_contact_person}
              {data?.customer_email ? (
                <>
                  {data?.customer_contact_person ? <> &nbsp; | &nbsp;</> : ''} {data?.customer_email}
                </>
              ) : (
                ''
              )}
              {data?.customer_phone ? (
                <>
                  {data?.customer_email || data?.customer_contact_person ? <> &nbsp; | &nbsp;</> : ''}
                  {data?.customer_phone}
                </>
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default MSOInfo
