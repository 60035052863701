import {
  Progress
  // Tooltip
} from 'antd'
// import { useEffect, useState } from 'react'
import './styles.scss'
import { getColorCode } from '../../../../utils/helper'

interface Props {
  value: number | any
  title: string
  percentage: number
  animate?: boolean
  onClick?: any
  active?: any
  id?: any
}

const WebSuperVisionTileStaging = ({ value, title, percentage, animate, onClick, active, id }: Props) => {
  const greenColor: any = getColorCode('greenDarkPink')
  const redColor: any = getColorCode('redCyan')

  // function formatNumber(number: any) {
  //   const absNumber = Math.abs(number)
  //   const numLength = absNumber.toString().length

  //   if (numLength >= 19) {
  //     return (number / 1e18).toFixed(1) + 'e'
  //   } else if (numLength >= 16) {
  //     return (number / 1e15).toFixed(1) + 'p'
  //   } else if (numLength >= 13) {
  //     return (number / 1e12).toFixed(1) + 't'
  //   } else if (numLength >= 10) {
  //     return (number / 1e9).toFixed(1) + 'b'
  //   } else if (numLength >= 7) {
  //     return (number / 1e6).toFixed(1) + 'm'
  //   } else if (numLength >= 4) {
  //     return (number / 1e3).toFixed(1) + 'k'
  //   }
  //   return number.toString()
  // }

  const borderColor = active && id ? (active === id ? redColor : greenColor) : greenColor
  const bgColor = active && id ? (active === id ? greenColor : redColor) : redColor

  return (
    <div className='web-supervision-till-wrapper' onClick={onClick}>
      <Progress
        type='circle'
        size={100}
        strokeColor={borderColor}
        className={`position-relative ${onClick ? 'cursor-pointer' : ''}`}
        percent={percentage}
        format={() => (
          <div className='inner-circle'>
            <div
              className={`${animate ? 'animate-pulse' : ''} web-supervision-circle`}
              style={{ backgroundColor: `${bgColor}`, fontSize: 'large' }}
              title={value}
            >
              <div
                style={{
                  fontSize: 'large',
                  whiteSpace: 'nowrap',
                  overflow: `${value.length > 4 ? 'hidden' : 'unset'}`,
                  textOverflow: 'ellipsis',
                  padding: '0px 5px'
                }}
                title={value}
              >
                {value}
              </div>
            </div>
          </div>
        )}
      />
      {/* <CircularProgress
        value={percentage}
        thickness="4px"
        size="100px"
        color="#A3D696"
        className="relative"
      >
        <div className="absolute top-0 left-0 right-0 bottom-0  flex flex-col justify-center items-center gap-y-[15px]">
          <div className={`${animate ? 'animate-pulse' : ''} web-supervision-circle rounded-full bg-alerts-critical tex-white`}>
            {value >= 1000 ? (value /1000).toFixed(1) + "K" : value}
          </div>
        </div>
      </CircularProgress> */}
      <span className={`web-supervision-till-title`}>{title}</span>
    </div>
  )
}

export default WebSuperVisionTileStaging
