/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material'
import { Box } from '@mui/material'
import CustomeTabs from '../../components/CustomeTabs'
import ListofDevices from './ListofDevices'
import ListofUsers from './ListofUsers'

const MsoDetails = ({
  details,
  setCustomerOpenModal,
  setEdit,
  setEditData,
  setSnackBarInfo,
  setReCall,
  handleClose,
  setDeleteMSOModal,
  setDisplaySearch,
  displaySearch
}: any) => {
  const tabsList = [
    {
      label: 'DEVICES',
      testId: 'testid_list_devices',
      tab: (
        <>
          <ListofDevices
            handleClose={handleClose}
            setDisplaySearch={setDisplaySearch}
            displaySearch={displaySearch}
            setCustomerOpenModal={setCustomerOpenModal}
            details={details}
            setDeleteMSOModal={setDeleteMSOModal}
            setEdit={setEdit}
            setEditData={setEditData}
            setSnackBarInfo={setSnackBarInfo}
            setReCall={setReCall}
          />
        </>
      )
    },
    {
      label: 'ADMINS',
      testId: 'testid_list_users',
      tab: (
        <>
          <ListofUsers
            details={details}
            setCustomerOpenModal={setCustomerOpenModal}
            setEdit={setEdit}
            setEditData={setEditData}
            setSnackBarInfo={setSnackBarInfo}
            setDeleteMSOModal={setDeleteMSOModal}
          />
        </>
      )
    }
  ]

  const filterTabLists =
    process.env.REACT_APP_REALM === 'Scalingtests'
      ? tabsList
      : tabsList.filter(res => res.testId !== 'testid_list_users')

  return (
    <>
      <Box className='bg-white m-2 rounded'>
        <Box className='text-end mt-1'>
          <Close
            className='cursor-pointer'
            onClick={() => {
              handleClose()
              setDisplaySearch(false)
            }}
          />
        </Box>
        {process.env.REACT_APP_REALM !== 'Scalingtests' ? (
          <ListofDevices
            handleClose={handleClose}
            setDisplaySearch={setDisplaySearch}
            displaySearch={displaySearch}
            setCustomerOpenModal={setCustomerOpenModal}
            details={details}
            setDeleteMSOModal={setDeleteMSOModal}
            setEdit={setEdit}
            setEditData={setEditData}
            setSnackBarInfo={setSnackBarInfo}
            setReCall={setReCall}
          />
        ) : (
          <CustomeTabs tabsList={filterTabLists} className='gate_way_logs p-0 shadow-none !min-h-0' />
        )}
      </Box>
    </>
  )
}
export default MsoDetails
