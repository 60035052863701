/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid2 as Grid, Tooltip, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Close } from '@mui/icons-material'
import {
  GetMSOUserData,
  keyClockCreateUser,
  keyClockEditUser,
  MSOUserAddInGroup,
  MSOUserMailSend,
  updateMSO,
  useAddMSO,
  useDeleteMSO,
  useGetWhiteLabel,
  useKeyClockAddMSO,
  useKeyClockDeleteMSO,
  useMSOUserAdd,
  useSendMail
} from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'
import { getColorCode } from '../../utils/helper'
import { emailVal } from '../../utils/lib/utils'

interface AddMSOFormProps {
  customer_name: string
  customer_email: string
  customer_phone: string
  customer_address: string
  white_label: string
  customer_contact_person: string
}

interface userCreatePayloadProps {
  email: string
  username: string
  firstName: string
  lastName: string
  enabled: boolean
  emailVerified: boolean
  requiredActions: string[]
}

const MsoAddEdit = ({
  edit,
  handleClose,
  editData,
  setSnackBarInfo,
  deviceOpenModal,
  setDeviceOpenModal,
  setReCall
}: any) => {
  const [title, setTitle] = useState('Add MSO')
  const [MSOName, setMSOName] = useState('')
  const [MSOId, setMSOId] = useState('')
  const theme = useTheme()

  const addMSOCustomer = useAddMSO()
  const addMSOKeyClock = useKeyClockAddMSO()
  const updateMutate = updateMSO()
  const sentMail = useSendMail()
  const addMSOUserInGroup = useMSOUserAdd()
  const { openModal, setOpenModal } = useSessionContex()
  const { data, refetch } = useDeleteMSO(MSOId)
  const deleteKeyClock = useKeyClockDeleteMSO()
  const { data: whiteLabelData } = useGetWhiteLabel(MSOName)

  // .matches(/^(\+?[0-9-.]+)$/, 'Only numbers, "+" and "-" are allowed')
  const validationSchema = Yup.object().shape({
    customer_phone: Yup.string()
      .matches(
        // /^(\+?[0-9-+.]+|\(?\d{3}\)?[0-9-+.]+)$/,
        /^(\+?[0-9-+.]+|\(?\d{3}\)?-?[0-9-+.]+)$/,
        'Only numbers, "-" and "+" are allowed'
      )
      .test('is-first-char-plus', 'If "+" is used, it must be the first character', (value: any) => {
        const a = value && (value.indexOf('+') === 0 ? value.substring(1) : value)
        return a ? !a.includes('+') : true
      })
      .test('is-last-char-minus', 'If "-", it cannot be the first or last character', (value: any) => {
        return value ? value.indexOf('-') !== 0 && value.lastIndexOf('-') !== value.length - 1 : true
      })
      .max(15, 'Phone number cannot exceed 15 characters'),
    // .required('Please enter contact number')
    // .min(10, 'Contact number must be 10 characters')
    // .matches(phoneNumberRegex, 'Please enter valid value'),
    // customer_name: Yup.string().required('Please enter Name'),
    customer_email: Yup.string()
      .email('Please enter a valid email')
      .matches(emailVal, 'Invalid email')
      .required('Please enter contact email'),
    customer_address: Yup.string(),
    // .required('Please enter MSO address'),
    white_label: Yup.string().trim().required('A valid name is required to create a MSO'),
    customer_contact_person: Yup.string().required('Please enter MSO contact person name')
  })

  const formik: any = useFormik({
    initialValues: {
      customer_name: editData?.customer_name || '',
      customer_email: editData?.customer_email || '',
      customer_phone: editData?.customer_phone || '',
      customer_address: editData?.customer_address || '',
      white_label: editData?.white_label?.trim()?.replace(/\s+/g, ' ') || '',
      customer_contact_person: editData?.customer_contact_person || ''
    },
    validationSchema,
    onSubmit: (values: AddMSOFormProps, actions: any) => {
      actions.setSubmitting(true)
      setMSOName(values.white_label?.trim()?.replace(/\s+/g, ' '))
      if (edit) {
        const data = {
          values,
          id: editData?.id
        }
        updateMutate.mutate(data, {
          onSuccess: async (data: any) => {
            setTimeout(() => {
              actions.setSubmitting(false)
            }, 200)
            if (data === 401) {
              !openModal && setOpenModal(true)
            }
            setSnackBarInfo({
              isOpen: true,
              message: 'MSO Edited Successfully.',
              type: 'Closed'
            })

            if (process.env.REACT_APP_REALM === 'Scalingtests') {
              await handleEditUserFunction(values, actions)
            } else {
              formik.resetForm()
              if (deviceOpenModal) {
                setDeviceOpenModal(false)
              }
              handleClose()
              setReCall(true)
            }
          },
          onError: (error: any) => {
            actions.setSubmitting(false)
            if (error?.response?.status === 23505 || error?.response?.status === 409) {
              setSnackBarInfo({
                isOpen: true,
                message:
                  error.response.data.errorMessage ||
                  `The name given for the MSO ${values?.white_label} is already in use. Please enter a unique MSO name.`,
                type: 'redToRed'
              })
            } else {
              setSnackBarInfo({
                isOpen: true,
                message: `${error ? error : `Failed to ${edit ? 'update' : 'add'} MSO.`}`,
                type: 'redToRed'
              })
            }
          }
        })
      } else {
        const keyPayload = {
          name: values.white_label?.trim()?.replace(/\s+/g, ' '),
          attributes: {
            'custom:whitelabel': [values.white_label?.trim()?.replace(/\s+/g, ' ')]
          }
        }
        addMSOKeyClock.mutate(keyPayload, {
          onSuccess: async (data: any) => {
            const mailPayload = {
              mso_name: values.white_label?.trim()?.replace(/\s+/g, ' ')
            }
            sentMail.mutate(mailPayload, {
              onSuccess: (data: any) => {
                if (data !== 401) {
                  setSnackBarInfo({
                    isOpen: true,
                    message: 'MSO Added Successfully.',
                    type: 'Closed'
                  })
                }

                setTimeout(() => {
                  actions.setSubmitting(false)
                }, 200)
              },
              onError: (error: any) => {
                setTimeout(() => {
                  actions.setSubmitting(false)
                }, 200)
              }
            })

            if (process.env.REACT_APP_REALM === 'Scalingtests') {
              const userCreatePayload: userCreatePayloadProps = {
                email: values.customer_email,
                username: values.customer_email,
                firstName: values.customer_contact_person,
                lastName: '',
                enabled: true,
                emailVerified: false,
                requiredActions: ['VERIFY_EMAIL', 'UPDATE_PASSWORD']
                // groups: [values.white_label?.trim()?.replace(/\s+/g, ' '), 'Admin', 'Support']
              }

              await handleAddUserFunction(userCreatePayload, values, actions)
            } else {
              addMSOCustomer.mutate({ ...values, white_label: values.white_label?.trim()?.replace(/\s+/g, ' ') })
              formik.resetForm()
              setReCall(true)
              handleClose()
              setTimeout(() => {
                actions.setSubmitting(false)
              }, 200)
            }

            if (data === 401) {
              !openModal && setOpenModal(true)
            }
          },
          onError: (error: any) => {
            actions.setSubmitting(false)
            if (error?.response?.status === 23505 || error?.response?.status === 409) {
              setSnackBarInfo({
                isOpen: true,
                message:
                  error.response.data.errorMessage ||
                  `The name given for the MSO ${values?.white_label} is already in use. Please enter a unique MSO name.`,
                type: 'redToRed'
              })
            } else {
              //MSO Name field requires a unique entry and '[value]' has already been used
              setSnackBarInfo({
                isOpen: true,
                message: `${error ? error?.message : `Failed to ${edit ? 'update' : 'add'} MSO.`}`,
                type: 'redToRed'
              })
            }
          }
        })
      }
    },
    validateOnBlur: true, // Enable validation onBlur
    validateOnChange: false // Disable validation onChange
  })

  const mailSentFunction = async (id: string, children?: () => void) => {
    if (id) {
      try {
        await MSOUserMailSend(id)
        setSnackBarInfo({
          isOpen: true,
          message: 'Admin User Mail Sent Successfully.',
          type: 'Closed'
        })
        // Call the children callback if provided
        if (children) {
          children()
        }
      } catch (error: any) {
        console.log('error form the mail fuction', error)
        setSnackBarInfo({
          isOpen: true,
          message:
            error?.response?.status === 409
              ? error?.response?.data?.errorMessage || 'Failed to sent mail'
              : error?.response?.status === 404
              ? error?.response?.data?.error
              : error?.message || `Failed to sent mail.`,
          type: 'redToRed'
        })
      }
    } else {
      setSnackBarInfo({
        isOpen: true,
        message: 'User Id not found...',
        type: 'redToRed'
      })
    }
  }

  const handleAddUserFunction = async (
    userCreatePayload: userCreatePayloadProps,
    values: AddMSOFormProps,
    actions: any
  ) => {
    try {
      const resposne = await keyClockCreateUser(userCreatePayload)
      if (resposne?.status === 201) {
        setSnackBarInfo({
          isOpen: true,
          message: 'User Created Successfully.',
          type: 'Closed'
        })
        addMSOCustomer.mutate({ ...values, white_label: values.white_label?.trim()?.replace(/\s+/g, ' ') })

        try {
          const data = await GetMSOUserData(values.customer_email)
          if (data.status === 200) {
            addMSOUserInGroup.mutate(
              {
                group_name: values.white_label?.trim()?.replace(/\s+/g, ' '),
                id: data.data.id,
                is_super_admin: true
              },
              {
                onSuccess: async (response: any) => {
                  console.log('data======group success======>', response)
                  if (!data?.data?.emailVerified) {
                    await mailSentFunction(data.data.id, () => {
                      // Perform the actions you want after the mail is sent
                      formik.resetForm()
                      handleClose()
                      setReCall(true)
                      setTimeout(() => {
                        actions.setSubmitting(false)
                      }, 200)
                    })
                  }
                },
                onError: (error: any) => {
                  console.log('error ---------- group error ========>', error)
                  setSnackBarInfo({
                    isOpen: true,
                    message: `${error ? error?.message : `Failed to add MSO User In Group.`}`,
                    type: 'redToRed'
                  })
                }
              }
            )
          }
        } catch (error: any) {
          console.log('catch data from get MSO USer Data', error)
          formik.resetForm()
          setTimeout(() => {
            actions.setSubmitting(false)
          }, 200)
          if (error?.response?.status === 409) {
            setSnackBarInfo({
              isOpen: true,
              message: error?.response?.data?.errorMessage || 'User email not exists',
              type: 'redToRed'
            })
          } else {
            setSnackBarInfo({
              isOpen: true,
              message: `${error ? error?.message : `Failed to get admin user.`}`,
              type: 'redToRed'
            })
          }
        }
      }
    } catch (error: any) {
      console.log('catch error', error)
      if (error?.response?.status === 409) {
        console.log('inside this catch error --------->')
        await handleCheckUserExist(values, actions)
        // setSnackBarInfo({
        //   isOpen: true,
        //   message:
        //     error?.response?.data?.errorMessage === 'User exists with same username'
        //       ? 'User already exist in another MSO'
        //       : 'User exists with same email',
        //   type: 'redToRed'
        // })
        // handleDeleteMSO(values.white_label?.trim()?.replace(/\s+/g, ' '), 'userCreate')
      } else {
        setSnackBarInfo({
          isOpen: true,
          message: `${error ? error?.message : `Failed to Create admin user.`}`,
          type: 'redToRed'
        })
        setTimeout(() => {
          actions.setSubmitting(false)
        }, 1000)
      }
    }
  }

  const handleCheckUserExist = async (values: AddMSOFormProps, actions: any) => {
    console.log('values===========>', values)
    try {
      const data = await GetMSOUserData(values.customer_email)
      if (data.status === 200) {
        console.log('data==========>', data)
        try {
          const apiResponse = await MSOUserAddInGroup({
            group_name: values.white_label?.trim()?.replace(/\s+/g, ' '),
            id: data.data?.id,
            is_super_admin: true
          })
          console.log('apiResponse===========>', apiResponse)
          if (apiResponse.status === 204) {
            addMSOCustomer.mutate({ ...values, white_label: values.white_label?.trim()?.replace(/\s+/g, ' ') })
            if (!data?.data?.emailVerified) {
              await mailSentFunction(data.data.id, () => {
                // Perform the actions you want after the mail is sent
                formik.resetForm()
                handleClose()
                setReCall(true)
                setTimeout(() => {
                  actions.setSubmitting(false)
                }, 200)
              })
            }
          }
        } catch (error: any) {
          if (error?.status === 400 || error?.response?.status === 400) {
            setSnackBarInfo({
              isOpen: true,
              message: error?.response?.data?.errorMessage,
              type: 'redToRed'
            })
            handleDeleteMSO(values.white_label?.trim()?.replace(/\s+/g, ' '), 'userCreate')
          } else {
            setSnackBarInfo({
              isOpen: true,
              message: error?.message,
              type: 'redToRed'
            })
            setTimeout(() => {
              actions.setSubmitting(false)
            }, 500)
          }
          console.log('check user catch ---------->', error)
        }
      }
    } catch (error: any) {
      console.log('catch error ---------->', error)
      setSnackBarInfo({
        isOpen: true,
        message: error?.message,
        type: 'redToRed'
      })
      setTimeout(() => {
        actions.setSubmitting(false)
      }, 500)
    }
  }

  const handleEditUserFunction = async (values: AddMSOFormProps, actions: any) => {
    try {
      const data = await GetMSOUserData(values.customer_email)
      if (data.status === 200) {
        const updatePayload = {
          firstName: values.customer_contact_person,
          lastName: '',
          id: data.data.id || ''
        }
        try {
          const updateResponse = await keyClockEditUser(updatePayload)
          if (updateResponse?.status === 204) {
            setSnackBarInfo({
              isOpen: true,
              message: 'User Updated Successfully.',
              type: 'Closed'
            })

            if (!data?.data?.emailVerified) {
              await mailSentFunction(updatePayload.id, () => {
                // Perform the actions you want after the mail is sent
                formik.resetForm()
                if (deviceOpenModal) {
                  setDeviceOpenModal(false)
                }
                handleClose()
                setReCall(true)
              })
            } else {
              formik.resetForm()
              if (deviceOpenModal) {
                setDeviceOpenModal(false)
              }
              handleClose()
              setReCall(true)
            }
          }
        } catch (error: any) {
          actions.setSubmitting(false)
          if (error?.response?.status === 409) {
            setSnackBarInfo({
              isOpen: true,
              message: error?.response?.data?.errorMessage || 'User exists with same email',
              type: 'redToRed'
            })
          } else {
            setSnackBarInfo({
              isOpen: true,
              message: `${error ? error?.message : `Failed to Update admin user.`}`,
              type: 'redToRed'
            })
          }
        }
      }
    } catch (error: any) {
      console.log('error', error)
      setTimeout(() => {
        actions.setSubmitting(false)
      }, 1000)
      if (error?.response?.status === 409) {
        setSnackBarInfo({
          isOpen: true,
          message: error?.response?.data?.errorMessage || 'User exists with same email',
          type: 'redToRed'
        })
      } else {
        setSnackBarInfo({
          isOpen: true,
          message: `${
            error?.response?.data?.errorMessage
              ? error?.response?.data?.errorMessage
              : error?.message || `Failed to Create admin user.`
          }`,
          type: 'redToRed'
        })
      }
    }
  }

  useEffect(() => {
    if (whiteLabelData?.id) {
      setMSOId(whiteLabelData?.id || '')
    }
  }, [whiteLabelData])

  useEffect(() => {
    if (MSOId) {
      handleDeleteMSO(MSOName)
    }
  }, [MSOId])

  const handleDeleteMSO = (MSOName: string, MSOCreate?: string) => {
    deleteKeyClock.mutate(MSOName, {
      onSuccess: (data: any) => {
        if (data === 401) {
          !openModal && setOpenModal(true)
        }
        !MSOCreate && refetch()
      },
      onError: (error: any) => {
        setSnackBarInfo({
          isOpen: true,
          message: error?.message || `Sorry, Can't removed`,
          type: 'redToRed'
        })
      }
    })
  }

  useEffect(() => {
    if (data === 204 || data === 200) {
      setSnackBarInfo({
        isOpen: true,
        message: 'MSO Removed Successfully',
        type: 'Closed'
      })
      setTimeout(() => {
        setReCall(true)
        handleClose()
      }, 500)
    }
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
    if (data?.response && data?.response.statusText) {
      setSnackBarInfo({
        isOpen: true,
        message: `${data?.response.statusText}`,
        type: 'redToRed'
      })
    }
  }, [data])

  useEffect(() => {
    setTitle(edit ? 'Edit MSO Customer' : 'Add MSO Customer')
  }, [edit])

  useEffect(() => {
    formik.resetForm()
  }, [])

  const newStyle: React.CSSProperties = { color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }
  const newSpanStyle: React.CSSProperties = { textWrap: 'nowrap', fontWeight: 'bold' }

  return (
    <Box className='rounded'>
      <Box
        sx={{
          width: '700px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>{title}</h3>

          <Close
            className='cursor-pointer'
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-2 bg-[#f7f8fe]' paddingLeft={5} paddingRight={5}>
          <form noValidate onSubmit={formik.handleSubmit} className='pt-2'>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid margin='auto' size={{ md: 4, xs: 12, sm: 12 }}>
                  <span style={newSpanStyle}>MSO Name: *</span>
                </Grid>
                <Grid size={{ md: 8, xs: 12, sm: 12 }}>
                  <input
                    type='text'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.white_label}
                    name='white_label'
                    placeholder='Please enter MSO name'
                    style={
                      formik.errors.white_label && formik.touched.white_label
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    disabled={edit}
                    data-testid='testid_mso_name'
                  />
                  {formik.errors.white_label && formik.touched.white_label && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.white_label}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid margin='auto' size={{ md: 4, xs: 12, sm: 12 }}>
                  <span style={newSpanStyle}>MSO Address:</span>
                </Grid>
                <Grid size={{ md: 8, xs: 12, sm: 12 }}>
                  <textarea
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_address}
                    name='customer_address'
                    placeholder='Please enter MSO address'
                    style={{
                      resize: 'none',
                      overflow: 'auto',
                      border: `${
                        formik.errors.customer_address && formik.touched.customer_address
                          ? `1px solid ${getColorCode('redToBlue')}`
                          : `1px solid #FFF`
                      }`
                    }}
                    rows={3}
                    data-testid='testid_mso_address'
                  ></textarea>
                  {formik.errors.customer_address && formik.touched.customer_address && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_address}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid margin='auto' size={{ md: 4, xs: 12, sm: 12 }}>
                  <span style={newSpanStyle}>Contact Name: *</span>
                </Grid>
                <Grid size={{ md: 8, xs: 12, sm: 12 }}>
                  <input
                    type='text'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_contact_person}
                    name='customer_contact_person'
                    placeholder='Please enter MSO contact person name'
                    style={
                      formik.errors.customer_contact_person && formik.touched.customer_contact_person
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    data-testid='testid_contact_name'
                  />
                  {formik.errors.customer_contact_person && formik.touched.customer_contact_person && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_contact_person}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid margin='auto' size={{ md: 4, xs: 12, sm: 12 }}>
                  <span style={newSpanStyle}>Contact Email: *</span>
                </Grid>
                <Grid size={{ md: 8, xs: 12, sm: 12 }}>
                  <input
                    type='email'
                    className='p-2 w-full border-[1px] verifypin-form-input'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_email}
                    name='customer_email'
                    placeholder='Please enter contact email'
                    style={
                      formik.errors.customer_email && formik.touched.customer_email
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #FFF` }
                    }
                    data-testid='testid_contact_email'
                    disabled={edit && editData?.customer_email !== '' ? true : false}
                  />
                  {formik.errors.customer_email && formik.touched.customer_email && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_email}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid margin='auto' size={{ md: 4, xs: 12, sm: 12 }}>
                  <span style={newSpanStyle}>Contact Mobile:</span>
                </Grid>
                <Grid size={{ md: 8, xs: 12, sm: 12 }}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                        }
                      }
                    }}
                    title={
                      <Typography>
                        Allowed formats: 2124567890, +12124567890, +1-212-456-7890, 1-212-456-7890
                      </Typography>
                    }
                    placement='top'
                  >
                    <input
                      type='text'
                      className='p-2 w-full border-[1px] verifypin-form-input'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customer_phone}
                      name='customer_phone'
                      placeholder='Please enter contact mobile'
                      style={
                        formik.errors.customer_phone && formik.touched.customer_phone
                          ? { border: `1px solid ${getColorCode('redToBlue')}` }
                          : { border: `1px solid #FFF` }
                      }
                      maxLength={15}
                      data-testid='testid_contact_mobile'
                    />
                  </Tooltip>
                  {formik.errors.customer_phone && formik.touched.customer_phone && (
                    <span className='errorText' style={newStyle}>
                      {formik.errors.customer_phone}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>

            <div className='mt-4 mb-3 text-center'>
              <Button
                type='submit'
                variant='contained'
                size='medium'
                sx={{
                  marginRight: '10px',
                  background: getColorCode('infoBlue'),
                  '&:hover': {
                    backgroundColor: getColorCode('infoBlue')
                  }
                }}
                disabled={formik.isSubmitting}
              >
                {edit ? 'Update' : 'Add'}
              </Button>
            </div>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
export default MsoAddEdit
