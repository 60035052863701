import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { Button, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Formik } from 'formik'
import { Eye, EyeSlash } from 'iconsax-react'
import * as Yup from 'yup'
import { getColorCode } from '../../../../utils/helper'
// import './index.scss'

const ChangePassword = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  passwordValue = '',
  valuesChanged,
  setApiCallPASSWORD,
  setMailPass
}: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please enter password')
      .min(8, 'Password must be at least 8 characters long.')
      .max(63, 'Password must not exceed 63 characters.')
      .matches(
        /^[a-zA-Z0-9!@#$^&*()_+\-={}[\]|<>?/,.]{7,63}$/,
        `Password must be 8-63 characters long, and can include the following special characters: !@#$^&*()_+-={}[]|<>?/,. Spaces, quotes (" or '), percent (%), backslash (\\), colon (:), and semicolon (;) are not allowed.`
      ),
    confirmPassword: Yup.string()
      .required('Please enter password')
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .matches(
        /^[a-zA-Z0-9!@#$^&*()_+\-={}[\]|<>?/,.]{7,63}$/,
        `Password must be 8-63 characters long, and can include the following special characters: !@#$^&*()_+-={}[]|<>?/,. Spaces, quotes (" or '), percent (%), backslash (\\), colon (:), and semicolon (;) are not allowed.`
      )
  })
  const handleValidSubmit = (values: any, actions: any) => {
    // setApiCallPASSWORD(true)
    actions.setSubmitting(false)
    const newValues = { ...values }
    delete newValues.confirmPassword
    setMailPass(newValues.password)
    onSubmit(newValues)
  }

  useEffect(() => {
    setMailPass('')
  }, [])

  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      width={500}
      wrapClassName={'reset_wifi_wrapper'}
      centered={true}
    >
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleValidSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='reset_wifi_body_wrapper device-tab'>
              <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                <h3
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {title}
                </h3>
                <Close
                  className='cursor-pointer'
                  onClick={() => {
                    onClose()
                    resetForm()
                  }}
                />
              </div>
              <div className='mb-2 row-gap-1 text-center'>
                <div className='mb-3 mt-1'>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id='-password-login'
                    type={showPassword ? 'text' : 'password'}
                    // value={values.password}
                    // defaultValue={passwordValue}
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className='modal_input'
                    sx={{
                      // border: `1px solid #d9d9d900`,
                      borderRadius: '6px 0px 0px 6px',
                      borderRight: 'none',
                      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      }
                    }}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          edge='end'
                          sx={{ color: getColorCode('infoBlue') }}
                        >
                          {showPassword ? <Eye /> : <EyeSlash />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Enter New password'
                  />
                  {touched.password && errors.password && (
                    <span
                      className='errorText'
                      style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                    >
                      {errors?.password}
                    </span>
                  )}
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    id='confirmPassword-login'
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    name='confirmPassword'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className='modal_input mt-2'
                    sx={{
                      // border: `1px solid #d9d9d900`,
                      borderRadius: '6px 0px 0px 6px',
                      borderRight: 'none',
                      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${getColorCode('redToBlue')} !important`
                      }
                    }}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle confirm password visibility'
                          onClick={handleClickShowConfirmPassword}
                          edge='end'
                          sx={{ color: getColorCode('infoBlue') }}
                        >
                          {showConfirmPassword ? <Eye /> : <EyeSlash />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Enter Confirm Password'
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <span
                      className='errorText'
                      style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                    >
                      {errors.confirmPassword}
                    </span>
                  )}
                  <div className='mt-3'>
                    <Button
                      type='submit'
                      variant='contained'
                      size='medium'
                      sx={{
                        marginRight: '10px',
                        backgroundColor: getColorCode('blueBlue'),
                        '&:hover': {
                          backgroundColor: getColorCode('blueBlue')
                        }
                      }}
                      disabled={isSubmitting}
                    >
                      Set
                    </Button>

                    {/* <Button
                      onClick={() => {
                        resetForm()
                        onClose()
                      }}
                      size='medium'
                      variant='outlined'
                      sx={{
                        color: getColorCode('blueBlue'),
                        borderColor: getColorCode('blueBlue')
                      }}
                    >
                      Cancel
                    </Button> */}
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default ChangePassword
