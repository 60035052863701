/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, styled, Tooltip, tooltipClasses } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { Modal, Spin } from 'antd'
import { Edit, InfoCircle, Send2, Trash } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomerModal from '../../components/Customer-table/CustomerModal'
import { CustomTable, CustomTableHeaderRow, CustomTableRow } from '../../components/Customer-table/customers-tables'
import Loader from '../../components/Loader'
import { useForgotPassword } from '../../hook/Auth/useLogin'
import { useGetMSO, useGetMSOUsersList } from '../../hook/useAllCustomers'
import { useScreenWidth } from '../../hook/useScreenWidth'
import { Column } from '../../models/CustomerTable'
import { RootState } from '../../redux/deviceStatistics/types'
import { getColorCode } from '../../utils/helper'
import AddUserSection from './AddUserSection'
import DeleteMSOUser from './DeleteMSOUser'
import MSOInfo from './MSOInfo'
import { widthProps } from '../../lib/types'

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    color: #282828 !important;
    font-weight: 500;
    padding: 9px 16px;
  }
`

export interface UserData {
  id?: string
  firstName: string
  lastName: string
  email: string
  emailVerified?: boolean
  is_super_admin?: boolean
  passwordSet?: boolean
}

const ListofUsers = ({
  details,
  setCustomerOpenModal,
  setEdit,
  setEditData,
  setSnackBarInfo,
  setDeleteMSOModal
}: any) => {
  const screenWidth = useScreenWidth()
  const [customers, setCustomers] = useState<UserData[]>([])
  const [addUserModalOpen, setAddUserModalOpen] = useState(false)
  const [isEditModalShow, setIsEditModalShow] = useState(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean }>({})
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editUserData, setEditUserData] = useState<UserData | undefined>(undefined)
  const [pageLoader, setPageLoader] = useState(true)
  const { data: MSOData, refetch } = useGetMSO(details?.id)
  const {
    data: MSOUsersList,
    refetch: MSOUserFetch,
    isFetching,
    isLoading
  } = useGetMSOUsersList({ group_name: details?.white_label })

  useEffect(() => {
    if (details?.white_label) {
      MSOUserFetch()
    }
  }, [details?.white_label])

  useEffect(() => {
    if (isFetching || isLoading) {
      setPageLoader(true)
    } else {
      setTimeout(() => {
        setPageLoader(false)
      }, 500)
    }
  }, [isFetching, isLoading])

  useEffect(() => {
    if (MSOUsersList && MSOUsersList?.length !== 0 && !pageLoader) {
      setCustomers(MSOUsersList)
    }
  }, [MSOUsersList, pageLoader])

  const [data, setData] = useState<{
    customer_address: string
    customer_contact_person: string
    customer_email: string
    customer_name: string
    customer_phone: string
    id: number
    white_label: string
  }>({
    customer_address: '',
    customer_contact_person: '',
    customer_email: '',
    customer_name: '',
    customer_phone: '',
    id: 1,
    white_label: ''
  })
  const [width, setWidth] = useState<widthProps>({
    small: '150px',
    normal: '200px',
    regular: '250px',
    large: '300px',
    extraLarge: '350px'
  })

  useEffect(() => {
    if (details.id) {
      refetch()
    }
  }, [details])

  useEffect(() => {
    if (MSOData && MSOData.length !== 0) {
      setData(MSOData[0])
    }
  }, [MSOData])

  useEffect(() => {
    if (!screenWidth) return
    if (screenWidth > 2880) {
      setWidth({
        small: '210px',
        normal: '260px',
        regular: '400px',
        large: '450px',
        extraLarge: '550px'
      })
    } else if (screenWidth > 1780) {
      setWidth({
        small: `${(screenWidth * 10) / 100}px`,
        normal: `${(screenWidth * 10) / 100}px`,
        regular: `${(screenWidth * 14) / 100}px`,
        large: `${(screenWidth * 15) / 100}px`,
        extraLarge: `${(screenWidth * 19.75) / 100}px`
      })
    } else {
      setWidth({
        small: '150px',
        normal: '200px',
        regular: '250px',
        large: '300px',
        extraLarge: '280px'
      })
    }
  }, [screenWidth])

  const columns: any = [
    {
      id: 'first_name',
      label: 'First Name',
      minWidth: screenWidth >= 1440 ? width.extraLarge : width.normal,
      align: 'left'
    },
    {
      id: 'last_name',
      label: 'Last Name',
      minWidth: screenWidth >= 1440 ? width.extraLarge : width.normal,
      align: 'left'
    },
    {
      id: 'email',
      label: 'Email',
      minWidth: screenWidth >= 1440 ? width.extraLarge : width.normal,
      align: 'left'
    }
  ]
  const defaultUserData = useSelector((state: RootState) => state.user)
  const { mutate } = useForgotPassword()

  const mailSentFunction = (id: string, data?: UserData) => {
    const userEmail: any = data?.email || ''
    if (id) {
      setDisabledButtons(prev => ({
        ...prev,
        [id]: true // Disable the button for this user
      }))
      setButtonLoading(true)

      if (!data?.emailVerified && !data?.passwordSet) {
        mutate(userEmail, {
          onSuccess: (status: any) => {
            if (status === 204) {
              setSnackBarInfo({
                isOpen: true,
                message: 'Admin User Mail Sent Successfully.',
                type: 'Closed'
              })
              setDisabledButtons(prev => ({
                ...prev,
                [id]: false // Re-enable the button after the operation
              }))
              setButtonLoading(false)
            }
          },
          onError: (error: any) => {
            setDisabledButtons(prev => ({
              ...prev,
              [id]: false // Re-enable the button after the operation
            }))
            setButtonLoading(false)
            setSnackBarInfo({
              isOpen: true,
              message:
                error?.response?.status === 409
                  ? error?.response?.data?.errorMessage || 'Failed to sent mail'
                  : error?.response?.status === 404
                  ? error?.response?.data?.error
                  : error?.message || `Failed to sent mail.`,
              type: 'redToRed'
            })
          }
        })
      } else {
        if (data?.emailVerified && !data?.passwordSet) {
          mutate(userEmail, {
            onSuccess: (status: any) => {
              if (status === 204) {
                setSnackBarInfo({
                  isOpen: true,
                  message: 'Admin User Mail Sent Successfully.',
                  type: 'Closed'
                })
                setDisabledButtons(prev => ({
                  ...prev,
                  [id]: false // Re-enable the button after the operation
                }))
                setButtonLoading(false)
              }
            },
            onError: (error: any) => {
              setDisabledButtons(prev => ({
                ...prev,
                [id]: false // Re-enable the button after the operation
              }))
              setButtonLoading(false)
              setSnackBarInfo({
                isOpen: true,
                message:
                  error?.response?.status === 409
                    ? error?.response?.data?.errorMessage || 'Failed to sent mail'
                    : error?.response?.status === 404
                    ? error?.response?.data?.error
                    : error?.message || `Failed to sent mail.`,
                type: 'redToRed'
              })
            }
          })
        }
      }
    } else {
      setDisabledButtons(prev => ({
        ...prev,
        [id]: false // Re-enable the button after the operation
      }))
      setButtonLoading(false)
      setSnackBarInfo({
        isOpen: true,
        message: 'User Id not found...',
        type: 'redToRed'
      })
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: '16px', mb: '1rem' }}>
        <MSOInfo
          data={data}
          defaultUserData={defaultUserData}
          isFetching={isFetching}
          isLoading={isLoading}
          setCustomerOpenModal={setCustomerOpenModal}
          setDeleteMSOModal={setDeleteMSOModal}
          setEdit={setEdit}
          setEditData={setEditData}
        />
        <Button
          variant='contained'
          size='medium'
          sx={{
            background: getColorCode('infoBlue'),
            margin: '10px 0px 0px 10px',
            '&:hover': {
              backgroundColor: getColorCode('infoBlue')
            },
            textAlign: 'end'
          }}
          onClick={() => setAddUserModalOpen(true)}
          data-testid='testid_add_user'
        >
          Add Admin
        </Button>
      </Box>

      {pageLoader || !data ? (
        <>
          <Loader />
        </>
      ) : customers && customers?.length !== 0 ? (
        <div style={{ overflow: 'auto', width: '100%' }} className='device_table_wrapper rounded '>
          <TableContainer
            sx={{
              msOverflowStyle: 'none',
              overflowX: 'auto',
              overflowY: 'auto',
              maxHeight: '55vh',

              '&::-webkit-scrollbar': {
                width: 0,
                height: 0
              },
              marginBottom: '1.5rem'
            }}
          >
            <CustomTable
              className='table-wrapper'
              stickyHeader
              aria-label='sticky table'
              style={{ borderSpacing: '0px 12px' }}
            >
              <TableHead>
                <CustomTableHeaderRow sx={{ borderRadius: 15, height: '60px' }}>
                  <TableCell
                    sx={{
                      fontWeight: '600',
                      background: '#f7f8fe'
                    }}
                  ></TableCell>
                  {columns.map((column: Column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        fontWeight: '600',
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        background: '#f7f8fe',
                        textAlign: column.id === 'email_verified' ? 'center' : 'left'
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </CustomTableHeaderRow>
              </TableHead>
              <TableBody>
                {customers &&
                  customers?.map((res: UserData, index: number) => {
                    return (
                      <CustomTableRow
                        styledcolor={'#cbcfde'}
                        key={`device_row${index}`}
                        style={{ cursor: 'default' }}
                        className='!mb-0 !rounded-[10px]'
                      >
                        <StyledTableCell>
                          <div className='flex items-center'>
                            {(!defaultUserData?.isMSOPortalAdmin || data?.customer_email !== res.email) && (
                              <Tooltip title='Edit Admin' arrow placement='top'>
                                <Edit
                                  size={16}
                                  className='m-2 cursor-pointer'
                                  onClick={(e: any) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setIsEditModalShow(true)
                                    setAddUserModalOpen(true)
                                    setEditUserData(res)
                                  }}
                                />
                              </Tooltip>
                            )}
                            {(!res?.emailVerified || !res?.passwordSet) && (
                              <IconButton
                                aria-label='Send email icon'
                                onClick={(e: any) => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  mailSentFunction(res?.id || '', res)
                                }}
                                disabled={buttonLoading || false}
                                className={`hover:!bg-transparent cursor-pointer text-black disabled:!cursor-not-allowed `}
                              >
                                <Tooltip
                                  title='Resend'
                                  arrow
                                  placement='top'
                                  slotProps={{
                                    popper: {
                                      sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginTop: '-15px'
                                          }
                                      }
                                    }
                                  }}
                                >
                                  {disabledButtons[res?.id || ''] ? (
                                    <Spin
                                      size='small'
                                      style={{
                                        color:
                                          process.env.REACT_APP_COLOR === 'hitron'
                                            ? '#004C97'
                                            : process.env.REACT_APP_COLOR === 'customer'
                                            ? '#ff974c'
                                            : '#FF8181'
                                      }}
                                    />
                                  ) : (
                                    <Send2 size={16} color='#000' />
                                  )}
                                </Tooltip>
                              </IconButton>
                            )}
                            {!res?.is_super_admin &&
                              (defaultUserData?.isMSOSuperAdmin || defaultUserData?.isPlatformSuperAdmin) && (
                                <Tooltip title='Delete Admin' arrow placement='top'>
                                  <Trash
                                    color='red'
                                    size={18}
                                    className='m-2 cursor-pointer'
                                    onClick={(e: any) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setDeleteModal(true)
                                      setEditUserData(res)
                                    }}
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{res?.firstName}</StyledTableCell>
                        <StyledTableCell>{res?.lastName || '-'}</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            height: '60px',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            width: '100%'
                          }}
                        >
                          <div className='flex w-full items-center gap-2'>
                            {(!res?.emailVerified || !res?.passwordSet) && (
                              <Tooltip
                                title={
                                  !res?.emailVerified ? 'Not verified' : !res?.passwordSet ? 'Password not set' : ''
                                }
                                arrow
                                placement='top'
                              >
                                <InfoCircle size={16} className='cursor-pointer' color='red' />
                              </Tooltip>
                            )}
                            {res?.email}
                          </div>
                        </StyledTableCell>
                      </CustomTableRow>
                    )
                  })}
              </TableBody>
            </CustomTable>
          </TableContainer>
        </div>
      ) : (
        <Box className='rounded border-2 bg-white p-3 text-center' margin={3} sx={{ color: getColorCode('redToBlue') }}>
          No user found.
        </Box>
      )}

      {addUserModalOpen && (
        <CustomerModal
          open={addUserModalOpen}
          handleClose={() => {
            setAddUserModalOpen(false)
            setIsEditModalShow(false)
            setEditUserData(undefined)
          }}
        >
          <AddUserSection
            handleClose={() => {
              setAddUserModalOpen(false)
              setIsEditModalShow(false)
              setEditUserData(undefined)
            }}
            setSnackBarInfo={setSnackBarInfo}
            MSOData={data}
            isEditModalShow={isEditModalShow}
            editUserData={editUserData}
            MSOUserFetch={MSOUserFetch}
            refetch={refetch}
          />
        </CustomerModal>
      )}

      {deleteModal && (
        <Modal open={deleteModal} footer={null} closable={false} width={600} centered={true} keyboard={true}>
          <DeleteMSOUser
            handleClose={() => {
              setDeleteModal(false)
              setEditUserData(undefined)
            }}
            MSOData={data}
            setSnackBarInfo={setSnackBarInfo}
            editData={editUserData}
            MSOUserFetch={MSOUserFetch}
          />
        </Modal>
      )}
    </>
  )
}

export default ListofUsers
