import React, { FC } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getColorCode } from '../../../../utils/helper'
import moment from 'moment'

interface Props {
  data: any[] | any
  // filterLabel?: string
  // filteringDevice?: string
}

const TimeSupervisionChartStaging: FC<Props> = ({ data }) => {
  const sortedData = !!data
    ? data.some((item: any) => item.hasOwnProperty('datetime'))
      ? data.sort((a: any, b: any) => {
          return moment(a.datetime).isBefore(moment(b.datetime)) ? -1 : 1
        })
      : data.sort((a: any, b: any) => b.seconds - a.seconds)
    : data

  // const labelY = sortedData?.map((item: any) => +(item.seconds / 3600).toFixed(2) as unknown as number) || []
  const labelY = sortedData?.map((item: any) => +(item.seconds / 3600).toFixed(2) as unknown as number) || []

  const labelX =
    sortedData?.map((item: any) =>
      item?.datetime
        ? moment.utc(item?.datetime).local().format(`${process.env.REACT_APP_DATE_FORMAT}`)
        : item?.device_name || 'unknown'
    ) || []

  const options: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter: function () {
        return `<b>${sortedData[this?.point?.x].device_name || 'unknown'}</b>: ${this.point.y} hr`
      }
    },
    series: [
      {
        // `type: column` is required for type-checking this options as a column series
        showInLegend: false,
        type: 'column',
        data: labelY
      }
    ],
    xAxis: {
      categories: labelX,
      allowDecimals: false,
      labels: {
        step: 1
      }
    },
    yAxis: {
      title: {
        text: 'Hours'
      }
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        lineColor: 'black',
        lineWidth: 2,
        marker: {
          enabled: false
        },
        label: {
          useHTML: false
        }
      },
      column: {
        pointWidth: 12,
        color: getColorCode('chartPurpleBlue')
      }
    }
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default React.memo(TimeSupervisionChartStaging)
