/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@mui/icons-material'
import { Box, Button, Grid2 as Grid, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { sendRestPasswordMail } from '../../hook/Auth/useLogin'
import {
  GetMSOUserData,
  keyClockCreateUser,
  keyClockEditUser,
  MSOUserAddInGroup,
  MSOUserMailSend,
  updateMSO,
  useMSOUserAdd
} from '../../hook/useAllCustomers'
import { getColorCode } from '../../utils/helper'
import { emailVal } from '../../utils/lib/utils'
import { UserData } from './ListofUsers'

interface FormValues {
  first_name: string
  last_name: string
  user_email: string
}

interface AddUserSectionProps {
  handleClose?: any
  setSnackBarInfo?: any
  MSOData?: any
  isEditModalShow?: any
  editUserData?: UserData
  MSOUserFetch?: any
  refetch?: any
}

interface userCreatePayloadProps {
  email: string
  username: string
  firstName: string
  lastName: string
  enabled: boolean
  emailVerified: boolean
  requiredActions: string[]
}

const AddUserSection: React.FC<AddUserSectionProps> = ({
  handleClose,
  setSnackBarInfo,
  MSOData,
  isEditModalShow,
  editUserData,
  MSOUserFetch,
  refetch
}) => {
  const title = isEditModalShow ? 'Edit Admin' : 'Add Admin'
  const theme = useTheme()
  const addMSOUserInGroup = useMSOUserAdd()
  const updateMutate = updateMSO()
  const [IsAddButtonDisable, setIsAddButtonDisable] = useState(isEditModalShow ? false : true)

  useEffect(() => {
    if (isEditModalShow) {
      formik.setValues({
        first_name: editUserData?.firstName || '',
        last_name: editUserData?.lastName || '',
        user_email: editUserData?.email || ''
      })
    }
  }, [isEditModalShow])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string(),
    user_email: Yup.string()
      .email('Please enter a valid email')
      .matches(emailVal, 'Invalid email')
      .required('Email is required')
  })

  const formik = useFormik<FormValues>({
    initialValues: {
      first_name: '',
      last_name: '',
      user_email: ''
    },
    validationSchema,
    onSubmit: async (values: FormValues, actions: any) => {
      actions.setSubmitting(true)
      const userCreatePayload: userCreatePayloadProps = {
        email: values.user_email,
        username: values.user_email,
        firstName: values.first_name,
        lastName: values.last_name,
        enabled: true,
        emailVerified: false,
        requiredActions: ['VERIFY_EMAIL', 'UPDATE_PASSWORD']
        // groups: [MSOData?.white_label, 'Admin']
      }

      if (!isEditModalShow) {
        await handleAddUserFunction(userCreatePayload, values, actions)
      } else {
        if (editUserData?.is_super_admin) {
          const data = {
            values: {
              ...MSOData,
              customer_contact_person:
                values?.last_name?.length !== 0 ? `${values?.first_name} ${values?.last_name}` : values?.first_name
            },
            id: MSOData?.id
          }
          updateMutate.mutate(data, {
            onSuccess: (data: any) => {
              setTimeout(() => {
                actions.setSubmitting(false)
              }, 200)
            },
            onError: (error: any) => {
              actions.setSubmitting(false)
              if (error?.response?.status === 23505 || error?.response?.status === 409) {
                setSnackBarInfo({
                  isOpen: true,
                  message:
                    error.response.data.errorMessage ||
                    `The name given for the MSO ${MSOData?.white_label} is already in use. Please enter a unique MSO name.`,
                  type: 'redToRed'
                })
              } else {
                setSnackBarInfo({
                  isOpen: true,
                  message: `${error ? error : `Failed to ${isEditModalShow ? 'update' : 'add'} MSO.`}`,
                  type: 'redToRed'
                })
              }
            }
          })
        }

        await handleEditUserFunction(values, actions)
      }
    }
  })

  const handleEditUserFunction = async (values: FormValues, actions: any) => {
    const updatePayload = {
      firstName: values?.first_name,
      lastName: values?.last_name,
      id: editUserData?.id || ''
    }
    try {
      const updateResponse = await keyClockEditUser(updatePayload)
      if (updateResponse?.status === 204) {
        setSnackBarInfo({
          isOpen: true,
          message: 'Admin Updated Successfully.',
          type: 'Closed'
        })

        if (!editUserData?.emailVerified) {
          await mailSentFunction(
            updatePayload.id,
            () => {
              // Perform the actions you want after the mail is sent
              formik.resetForm()
              handleClose()
              setTimeout(() => {
                actions.setSubmitting(false)
                MSOUserFetch()
                editUserData?.is_super_admin && refetch()
              }, 200)
            },
            editUserData
          )
        } else {
          formik.resetForm()
          handleClose()
          setTimeout(() => {
            actions.setSubmitting(false)
            MSOUserFetch()
            editUserData?.is_super_admin && refetch()
          }, 200)
        }
      }
    } catch (error: any) {
      actions.setSubmitting(false)
      if (error?.response?.status === 409) {
        setSnackBarInfo({
          isOpen: true,
          message:
            error?.response?.data?.errorMessage === 'User exists with same username'
              ? 'User already exist in another MSO'
              : error?.message || 'User exists with same email',
          type: 'redToRed'
        })
      } else {
        setSnackBarInfo({
          isOpen: true,
          message: `${error ? error?.message : `Failed to Update admin user.`}`,
          type: 'redToRed'
        })
      }
    }
  }

  const handleAddUserFunction = async (userCreatePayload: userCreatePayloadProps, values: FormValues, actions: any) => {
    try {
      const userResponse = await keyClockCreateUser(userCreatePayload)
      if (userResponse?.status === 201) {
        setSnackBarInfo({
          isOpen: true,
          message: 'Admin Created Successfully.',
          type: 'Closed'
        })
        try {
          const data = await GetMSOUserData(values.user_email)
          if (data.status === 200) {
            addMSOUserInGroup.mutate(
              { group_name: MSOData?.white_label, id: data.data.id, is_super_admin: false },
              {
                onSuccess: async (response: any) => {
                  console.log('data======group success======>', response)
                  if (!data.data?.emailVerified) {
                    await mailSentFunction(
                      data.data.id,
                      () => {
                        // Perform the actions you want after the mail is sent
                        formik.resetForm()
                        handleClose()

                        setTimeout(() => {
                          actions.setSubmitting(false)
                          MSOUserFetch()
                        }, 200)
                      },
                      data.data
                    )
                  } else {
                    formik.resetForm()
                    handleClose()
                    setTimeout(() => {
                      actions.setSubmitting(false)
                      MSOUserFetch()
                    }, 200)
                  }
                },
                onError: (error: any) => {
                  console.log('error ---------- group error ========>', error)
                  setSnackBarInfo({
                    isOpen: true,
                    message: `${error ? error?.message : `Failed to get admin user.`}`,
                    type: 'redToRed'
                  })
                }
              }
            )
          }
        } catch (error: any) {
          formik.resetForm()
          handleClose()
          setTimeout(() => {
            actions.setSubmitting(false)
            MSOUserFetch()
          }, 200)
          if (error?.response?.status === 409) {
            setSnackBarInfo({
              isOpen: true,
              message:
                error?.response?.data?.errorMessage === 'User exists with same username'
                  ? 'User already exist in another MSO'
                  : error?.message,
              type: 'redToRed'
            })
          } else {
            setSnackBarInfo({
              isOpen: true,
              message: `${error ? error?.message : `Failed to get admin user.`}`,
              type: 'redToRed'
            })
          }
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        await handleCheckMSOUserExist(values, actions)
        // setSnackBarInfo({
        //   isOpen: true,
        //   message:
        //     error?.response?.data?.errorMessage === 'User exists with same username'
        //       ? 'User already exist in another MSO'
        //       : error?.message,
        //   type: 'redToRed'
        // })
      } else {
        actions.setSubmitting(false)

        setSnackBarInfo({
          isOpen: true,
          message: `${error ? error?.message : `Failed to Create admin user.`}`,
          type: 'redToRed'
        })
      }
    }
  }

  const handleCheckMSOUserExist = async (values: FormValues, actions: any) => {
    try {
      const data = await GetMSOUserData(values.user_email)
      if (data.status === 200) {
        try {
          const apiResponse = await MSOUserAddInGroup({
            group_name: MSOData?.white_label?.trim()?.replace(/\s+/g, ' '),
            id: data.data?.id,
            is_super_admin: false
          })
          if (apiResponse.status === 204) {
            if (!data.data?.emailVerified) {
              await mailSentFunction(
                data.data.id,
                () => {
                  // Perform the actions you want after the mail is sent
                  formik.resetForm()
                  handleClose()

                  setTimeout(() => {
                    actions.setSubmitting(false)
                    MSOUserFetch()
                  }, 200)
                },
                data.data
              )
            } else {
              formik.resetForm()
              handleClose()
              setTimeout(() => {
                actions.setSubmitting(false)
                MSOUserFetch()
              }, 200)
            }
          }
        } catch (error: any) {
          if (error?.status === 400 || error?.response?.status === 400) {
            setSnackBarInfo({
              isOpen: true,
              message: error?.response?.data?.errorMessage,
              type: 'redToRed'
            })
          } else {
            setSnackBarInfo({
              isOpen: true,
              message: error?.message,
              type: 'redToRed'
            })
          }
          actions.setSubmitting(false)
        }
      }
    } catch (error: any) {
      actions.setSubmitting(false)
      setSnackBarInfo({
        isOpen: true,
        message: error?.message,
        type: 'redToRed'
      })
    }
  }

  const newStyle: React.CSSProperties = { color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }
  const newSpanStyle: React.CSSProperties = { textWrap: 'nowrap', fontWeight: 'bold' }

  const mailSentFunction = async (id: string, children?: () => void, response?: UserData) => {
    if (id) {
      try {
        const userEmail: any = response?.email || ''
        if (!response?.emailVerified && !response?.passwordSet) {
          if (!isEditModalShow) {
            await MSOUserMailSend(id)
          } else {
            await sendRestPasswordMail(userEmail)
          }
        } else {
          if (response?.emailVerified && !response?.passwordSet) {
            await sendRestPasswordMail(userEmail)
          }
        }
        setSnackBarInfo({
          isOpen: true,
          message: 'Admin User Mail Sent Successfully.',
          type: 'Closed'
        })
        // Call the children callback if provided
        if (children) {
          children()
        }
      } catch (error: any) {
        console.log('error form the mail fuction', error)
        setSnackBarInfo({
          isOpen: true,
          message:
            error?.response?.status === 409
              ? error?.response?.data?.errorMessage || 'Failed to sent mail'
              : error?.response?.status === 404
              ? error?.response?.data?.error
              : error?.message || `Failed to sent mail.`,
          type: 'redToRed'
        })
      }
    } else {
      setSnackBarInfo({
        isOpen: true,
        message: 'User Id not found...',
        type: 'redToRed'
      })
    }
  }

  useEffect(() => {
    if (!isEditModalShow && formik.errors.user_email !== '') {
      setIsAddButtonDisable(true)
    }
  }, [formik.values.user_email, isEditModalShow])

  const handleCheckUserExist = async (email: string) => {
    try {
      const userData = await GetMSOUserData(email)
      formik.setFieldValue('first_name', userData?.data?.firstName?.trim(''))
      formik.setFieldValue('last_name', userData?.data?.lastName)
      formik.setFieldError('first_name', '')
      // formik.setFieldTouched('first_name', true)
      setIsAddButtonDisable(false)
    } catch (error: any) {
      setIsAddButtonDisable(false)
      console.log('error from check user', error)
      if (error?.response?.status === 404 || error.status === 404) {
        setSnackBarInfo({
          isOpen: true,
          message: 'User is available to add in MSO',
          type: 'Closed'
        })
        formik.setFieldValue('first_name', '')
        formik.setFieldValue('last_name', '')
      } else {
        setSnackBarInfo({
          isOpen: true,
          message: error?.message,
          type: 'redToRed'
        })
      }
    }
  }

  return (
    <>
      <Box className='rounded'>
        <Box
          sx={{
            width: '700px',
            [theme.breakpoints.down('md')]: {
              width: 'auto'
            }
          }}
        >
          <Box textAlign={'center'} display={'flex'} justifyContent={'space-between'} padding={2}>
            <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>{title}</h3>

            <Close
              className='cursor-pointer'
              onClick={() => {
                handleClose()
              }}
            />
          </Box>
          <Box className='pb-2 bg-[#f7f8fe] pt-2' paddingLeft={5} paddingRight={5}>
            <form onSubmit={formik.handleSubmit}>
              <Box marginTop={2}>
                <Grid container spacing={2}>
                  <Grid margin='auto' size={{ md: 12, xs: 12, sm: 12 }}>
                    <span style={newSpanStyle}>
                      Email: <span>*</span>
                    </span>
                    <Box className='flex items-center gap-2 justify-between'>
                      <input
                        id='user_email'
                        name='user_email'
                        type='email'
                        className='p-2 w-full border-[1px] verifypin-form-input mt-1'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.user_email}
                        placeholder='Please enter email'
                        style={
                          formik.errors.user_email
                            ? { border: `1px solid ${getColorCode('redToBlue')}` }
                            : { border: `1px solid #FFF` }
                        }
                        data-testid='testid_contact_email'
                        disabled={isEditModalShow ? true : false}
                      />
                      {!isEditModalShow && (
                        <Button
                          type='button'
                          variant='contained'
                          size='medium'
                          sx={{
                            marginRight: '10px',
                            background: getColorCode('infoBlue'),
                            '&:hover': {
                              backgroundColor: getColorCode('infoBlue')
                            }
                          }}
                          disabled={
                            formik.values.user_email !== ''
                              ? !formik.errors.user_email || formik.errors.user_email === ''
                                ? false
                                : true
                              : true
                          }
                          className='me-0 w-40'
                          onClick={() => handleCheckUserExist(formik.values.user_email)}
                        >
                          {'Check User'}
                        </Button>
                      )}
                    </Box>
                    {formik.errors.user_email && (
                      <span className='errorText' style={newStyle}>
                        {formik.errors.user_email}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Box>

              <Box marginTop={2}>
                <Grid container spacing={2}>
                  <Grid size={{ md: 6, xs: 12, sm: 12 }}>
                    <span style={newSpanStyle}>
                      First Name: <span>*</span>
                    </span>
                    <input
                      id='first_name'
                      name='first_name'
                      type='text'
                      className='p-2 w-full border-[1px] verifypin-form-input mt-1'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      placeholder='Please enter first name'
                      style={
                        formik.touched.first_name && formik.errors.first_name
                          ? { border: `1px solid ${getColorCode('redToBlue')}` }
                          : { border: `1px solid #FFF` }
                      }
                      data-testid='testid_contact_name'
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <span className='errorText' style={newStyle}>
                        {formik.errors.first_name}
                      </span>
                    )}
                  </Grid>
                  <Grid size={{ md: 6, xs: 12, sm: 12 }}>
                    <span style={newSpanStyle}>Last Name:</span>
                    <input
                      id='last_name'
                      name='last_name'
                      type='text'
                      className='p-2 w-full border-[1px] verifypin-form-input mt-1'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      placeholder='Please enter last name'
                      style={
                        formik.errors.last_name
                          ? { border: `1px solid ${getColorCode('redToBlue')}` }
                          : { border: `1px solid #FFF` }
                      }
                      maxLength={15}
                      data-testid='testid_contact_mobile'
                    />
                    {formik.errors.last_name && (
                      <span className='errorText' style={newStyle}>
                        {formik.errors.last_name}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Box>

              <div className='mt-4 mb-3 text-center'>
                <Button
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    marginRight: '10px',
                    background: getColorCode('infoBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('infoBlue')
                    }
                  }}
                  disabled={formik.isSubmitting || IsAddButtonDisable}
                >
                  {isEditModalShow ? 'Update' : 'Add'}
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AddUserSection
