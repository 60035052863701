import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserState } from '../deviceStatistics/types'

const initialState: UserState = {
  accessToken: null,
  refreshToken: null,
  userType: null,
  menuType: null,
  user: null,
  email: null,
  userId: null,
  whiteLabel: null,
  deleteMSO: null,
  deleteMSOUser: null,
  isPlatformSuperAdmin: false,
  isPlatformPortalAdmin: false,
  isMSOSuperAdmin: false,
  isMSOPortalAdmin: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserState>) {
      return { ...state, ...action.payload }
    },
    clearUserData(state) {
      return initialState
    }
  }
})

export const { setUserData, clearUserData } = userSlice.actions
export default userSlice.reducer
