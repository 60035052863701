import { Close } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Modal } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { commonCondition } from '../../../lib/utils'
import { getColorCode } from '../../../utils/helper'

interface FirmwareProps {
  changelog?: string
  date?: string
  description?: string
  dev_only?: boolean
  device_model?: string
  fw_hash?: string
  fw_version?: string
  fw_version_num?: number
  id?: number
  url?: string
}

const UpdateFirmware = ({ isOpen, onClose, onSubmit, title, subTitle, data, customerinfo, testId }: any) => {
  const [confirmation, setConfirmation] = useState(false)
  const [filterFirmwareData, setFilterFirmwareData] = useState<FirmwareProps[]>([])
  const [selected, setSelected] = useState('')

  useEffect(() => {
    const sortedData = [...data].sort((a: any, b: any) => {
      const dateB = new Date(b?.date).getTime()
      const dateA = new Date(a?.date).getTime()
      return dateB - dateA
    })

    let filteredData = []

    // Find the index of the current firmware version in the sorted data
    const currentIndex = sortedData.findIndex(item => item.fw_version === customerinfo.fw_version)

    if (currentIndex !== -1) {
      // If fw_version matches, get two entries above and below the matching entry
      filteredData = sortedData.slice(Math.max(currentIndex - 2, 0), currentIndex + 3)
    } else {
      // If fw_version doesn't match, show the sorted data as is
      filteredData = sortedData
    }

    setFilterFirmwareData(commonCondition ? filteredData : data)
  }, [data, customerinfo])

  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        width={500}
        wrapClassName={'reset_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3
              style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              {title}
            </h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            {!filterFirmwareData && <div className='mb-3 mt-3 text-lg font-bold'>Data not available</div>}
            {filterFirmwareData?.length === 0 && (
              <div className='mb-3 mt-3 text-lg font-bold'>No Firmware Update Available</div>
            )}
            {/* <div className='mb-1 mt-1 text-xl font-normal'>Live Versions</div> */}
            {filterFirmwareData?.length !== 0 && selected === '' && (
              <div className='text-sm font-normal'>Select the version</div>
            )}
            {filterFirmwareData?.length !== 0 &&
              filterFirmwareData
                ?.sort((a: any, b: any) => new Date(b?.date).getTime() - new Date(a?.date).getTime())
                ?.map((obj: FirmwareProps, key: number) => {
                  return (
                    <div
                      className={`border mb-3 pb-15 pt-3  ${selected === String(obj.id) ? 'bg-blue-200' : ''} `}
                      key={key}
                      onClick={() => {
                        setSelected(String(obj.id))
                      }}
                      data-testid={`${testId}_v${key}`}
                    >
                      <div className={`flex justify-between pl-8 pr-8 text-sm font-normal`}>
                        <div>
                          {obj.fw_version} {customerinfo?.fw_version === obj.fw_version ? '*' : ''}
                        </div>
                        <div>
                          {moment.utc(obj.date).local().format(`${process.env.REACT_APP_DATE_FORMAT}`)}
                          {/* {moment.tz(moment.utc(obj.date), timeZone).format(`${process.env.REACT_APP_DATE_FORMAT}`)}  */}
                        </div>
                      </div>
                    </div>
                  )
                })}

            {filterFirmwareData && filterFirmwareData?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    setConfirmation(true)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{
                    marginRight: '10px',
                    color: 'white',
                    background: getColorCode('infoBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('infoBlue')
                    }
                  }}
                  disabled={selected === ''}
                  data-testid={`${testId}_confirmation`}
                >
                  Update
                </Button>
                {/* <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{
                    color: getColorCode('infoBlue'),
                    borderColor: getColorCode('infoBlue')
                  }}
                >
                  Cancel
                </Button> */}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={confirmation}
        footer={null}
        closable={false}
        width={300}
        wrapClassName={'reset_confirmation_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>Confirm</h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            <h4>Are you sure you want to update the FW?</h4>

            {filterFirmwareData && filterFirmwareData?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    onSubmit(selected)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{
                    marginRight: '10px',
                    background: getColorCode('infoBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('infoBlue')
                    }
                  }}
                  disabled={selected === ''}
                  data-testid={`${testId}_ok_button`}
                >
                  Ok
                </Button>
                {/* <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{
                    color: getColorCode('infoBlue'),
                    borderColor: getColorCode('infoBlue')
                  }}
                >
                  Cancel
                </Button> */}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdateFirmware
