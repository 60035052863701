import { commonCondition } from '../../../lib/utils'
import SecurityOverviewCommon from '../common/security-overview'
import SecurityOverviewStaging from '../staging/security-overview'

const ParentalControlTable: React.FC<any> = ({ email, mac, gwuid }) => {
  return (
    <>
      {commonCondition ? (
        <SecurityOverviewStaging email={email} mac={mac} gwuid={gwuid} />
      ) : (
        <SecurityOverviewCommon email={email} mac={mac} />
      )}
    </>
  )
}

export default ParentalControlTable
