import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as React from 'react'

const CustomerModal = ({ open, setOpen, handleOpen, handleClose, children, width }: any) => {
  let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // borderRadius: '8px',
    // pt: 2,
    // px: 4,
    // pb: 3,
    '&:focus-visible': {
      outline: 'none !important'
    }
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby='child-modal-title'
        aria-describedby='child-modal-description'
        disableEscapeKeyDown={true}
      >
        <Box sx={{ ...style }}>{children}</Box>
      </Modal>
    </React.Fragment>
  )
}

export default CustomerModal
