import { commonCondition } from '../../../lib/utils'
import TimeSupervisionChartCommon from './common/time-supervision-chartCommon'
import TimeSupervisionChartStaging from './staging/time-supervision-chart-staging'

const TimeSupervisionChart: React.FC<any> = ({ data }) => {
  return (
    <>{commonCondition ? <TimeSupervisionChartStaging data={data} /> : <TimeSupervisionChartCommon data={data} />}</>
  )
}

export default TimeSupervisionChart
