/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Tooltip, Typography, styled } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import NotRegisteredHitron from '../../common-images/app-logo/HitronHomePgTilesIcons/NotRegisteredHitron'
import { CustomTable, CustomTableHeaderRow, CustomTableRow } from '../../components/Device-table/devices-tables'
import '../../components/Device-table/styles.scss'
import TableHeader from '../../components/Device-table/tableHeader'
import Loader from '../../components/Loader'
import { useDeviceData } from '../../hook/useAllCustomers'
import { useScreenWidth } from '../../hook/useScreenWidth'
import { Column } from '../../models/CustomerTable'
import { useSessionContex } from '../../SessionContex'
import { getColorCode } from '../../utils/helper'
import { widthProps } from '../../lib/types'

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    color: #282828 !important;
    font-weight: 500;
    padding: 9px 16px;
  }
`
const StyledTableLinkCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    text-decoration: underline;
    font-weight: 500;
    padding: 9px 16px;
  }
`

const DeviceDetails = ({
  setDisplaySearch,
  displaySearch,
  setRegister,
  setDeprovision,
  MSOData,
  setCallDeviceDetail,
  callDeviceDetail,
  inputValue,
  setDeviceGwId,
  setDisplayButton,
  setDeviceGwEmail,
  deviceArray,
  setDeviceArray
}: any) => {
  const { openModal, setOpenModal } = useSessionContex()

  const screenWidth = useScreenWidth()

  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [selectAllArray, setSelectAllArray] = useState([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageLoader, setPageLoader] = useState(true)
  const [count, setCount] = useState<any>(0)
  const {
    data,
    isFetching: deviceIsFetching,
    isLoading,
    refetch
  } = useDeviceData({
    white_label: (MSOData && MSOData[0]?.white_label) || '',
    offset,
    limit,
    inputValue
  })
  const [width, setWidth] = useState<widthProps>({
    small: '80px',
    normal: '120px',
    regular: '150px',
    large: '180px',
    extraLarge: '300px'
  })
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
    if (data && data.length !== 0) {
      if (data[1].total) {
        setCount(data[1].total.split('/')[1])
      }
      setDeviceArray([])
      if (data[0]?.data && data[0]?.data.length !== 0) {
        setDisplaySearch(true)
        const data1 = data[0]?.data?.map((obj: any) => obj.gw_uid)
        setSelectAllArray(data1)
      } else {
        setSelectAllArray([])
        setDisplaySearch(false)
      }
    }
    if (inputValue !== '') {
      setDisplaySearch(true)
    }
  }, [data])
  useEffect(() => {
    if (callDeviceDetail && MSOData !== 401 && MSOData && MSOData?.length !== 0) {
      refetch()
      setPageLoader(true)
      setTimeout(() => {
        setCallDeviceDetail(false)
      }, 500)
    }
  }, [callDeviceDetail])

  useEffect(() => {
    if (deviceIsFetching || isLoading) {
      setPageLoader(true)
      setDisplayButton(true)
    } else {
      setTimeout(() => {
        setPageLoader(false)
        setDisplayButton(false)
      }, 500)
    }
    setSelectAll(false)
  }, [deviceIsFetching, isLoading])

  const columns: Column[] = [
    {
      id: 'gwUid',
      label: 'Gateway UID',
      minWidth: width.extraLarge,
      align: 'left',
      // filter: 'gw_uid',
      display: true
    },
    {
      id: 'email',
      label: 'Registered',
      minWidth: width.extraLarge,
      align: 'left',
      // filter: 'email',
      display: true
    },

    {
      id: 'provision_timestamp',
      label: 'Date of Registration',
      minWidth: width.extraLarge,
      align: 'left',
      // filter: 'provision_timestamp',
      display: true
    },

    {
      id: 'model',
      label: 'Device Type',
      minWidth: width.extraLarge,
      align: 'left',
      // filter: 'model',
      display: true
    }
  ]

  useEffect(() => {
    if (!screenWidth) return
    if (screenWidth > 2500) {
      setWidth({ small: '140px', normal: '180px', regular: '210px', large: '240px', extraLarge: '260px' })
    } else if (screenWidth > 2000) {
      setWidth({ small: '100px', normal: '140px', regular: '170px', large: '200px', extraLarge: '220px' })
    } else {
      setWidth({
        small: '50px',
        normal: '120px',
        regular: '150px',
        large: '180px',
        extraLarge: '300px'
      })
    }
  }, [screenWidth])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
    setTimeout(() => {
      setCallDeviceDetail(true)
    }, 200)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
    setTimeout(() => {
      setCallDeviceDetail(true)
    }, 200)
  }

  // checkbox logic
  const [selectAll, setSelectAll] = useState<any>(false)

  const handleChange = (event: any, data: any) => {
    if (event.target.checked) {
      const newArray = [...deviceArray, data]

      setDeviceArray([...deviceArray, data])

      if (newArray.length === selectAllArray.length) {
        setSelectAll(true)
      } else {
        setSelectAll(false)
      }
    } else {
      const newData: any = deviceArray?.filter((obj: any) => obj !== data)
      setSelectAll(false)

      setDeviceArray(newData)
    }
  }
  const handleChangeSelectAll = (event: any) => {
    setSelectAll(event.target.checked)

    if (event.target.checked) {
      setDeviceArray(selectAllArray)
    } else {
      setDeviceArray([])
    }
  }

  return (
    <>
      {pageLoader || !data ? (
        <>
          <Loader />
        </>
      ) : data && data[0]?.data && data[0]?.data?.length !== 0 ? (
        <div style={{ overflow: 'auto', width: '100%' }} className='device_table_wrapper rounded'>
          <TableContainer
            sx={{
              msOverflowStyle: 'none',
              overflowX: 'auto',
              overflowY: 'auto',
              maxHeight: '54vh',

              '&::-webkit-scrollbar': {
                width: 0,
                height: 0
              }
            }}
          >
            <CustomTable className='table-wrapper' stickyHeader aria-label='sticky table'>
              <TableHead>
                <CustomTableHeaderRow sx={{ borderRadius: 15, height: '60px' }}>
                  <TableCell
                    sx={{
                      fontWeight: '600',
                      background: '#f7f8fe'
                    }}
                  >
                    <Checkbox
                      checked={selectAll}
                      onChange={handleChangeSelectAll}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell>
                  {columns.map((column: Column) => {
                    return column.display && <TableHeader column={column} />
                  })}
                </CustomTableHeaderRow>
              </TableHead>

              <TableBody>
                {data &&
                  data[0]?.data?.map((device: any, index: number) => {
                    const colorCode = device.is_online ? getColorCode('greenToGreen') : getColorCode('redToGray')

                    return (
                      <CustomTableRow styledcolor={colorCode} key={`device_row${index}`}>
                        <StyledTableCell>
                          <Checkbox
                            checked={deviceArray?.includes(device.gw_uid)}
                            onChange={(e: any) => handleChange(e, device.gw_uid)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onClick={e => e.stopPropagation()}
                          />
                        </StyledTableCell>
                        <StyledTableCell> {`${device.gw_uid}`}</StyledTableCell>

                        {device.email === '' ? (
                          device?.dev_mesh_role === 'CAP' ? (
                            <StyledTableLinkCell
                              onClick={(e: any) => {
                                e.stopPropagation()
                                setRegister(true)
                                setDeviceGwId(device.gw_uid)
                              }}
                              style={{ color: getColorCode('infoBlue') }}
                            >
                              Register Now
                            </StyledTableLinkCell>
                          ) : (
                            <StyledTableCell style={{ color: getColorCode('infoBlue') }}>-</StyledTableCell>
                          )
                        ) : (
                          <StyledTableCell>
                            <Box display={'flex'} alignItems={'center'}>
                              {device.email}
                              {device?.dev_mesh_role === 'CAP' && (
                                <Tooltip
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: '#707e8c'
                                      }
                                    },
                                    popper: {
                                      modifiers: [
                                        {
                                          name: 'offset',
                                          options: {
                                            offset: [0, -14]
                                          }
                                        }
                                      ]
                                    }
                                  }}
                                  title={<Typography>Deprovision</Typography>}
                                  arrow
                                >
                                  <Button
                                    title='Deprovision'
                                    className='p-0 hover:bg-transparent w-auto !min-w-0'
                                    onClick={(e: any) => {
                                      e.stopPropagation()
                                      setDeprovision(true)
                                      setDeviceGwId(device.gw_uid)
                                      setDeviceGwEmail(device.email)
                                    }}
                                  >
                                    <NotRegisteredHitron width={45} height={45} color={getColorCode('infoBlue')} />
                                  </Button>
                                </Tooltip>
                              )}
                            </Box>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          <Tooltip
                            slotProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#707e8c'
                                }
                              }
                            }}
                            placement='top'
                            title={
                              device.provision_timestamp &&
                              device.provision_timestamp !== null &&
                              !device.provision_timestamp.startsWith('1970')
                                ? moment.utc(device.provision_timestamp).local().fromNow()
                                : 'N/A'
                            }
                          >
                            <>
                              {device.provision_timestamp &&
                              device.provision_timestamp !== null &&
                              !device.provision_timestamp.startsWith('1970')
                                ? moment
                                    .utc(device.provision_timestamp)
                                    .local()
                                    .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                                : 'N/A'}
                            </>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>{device.model}</StyledTableCell>
                      </CustomTableRow>
                    )
                  })}
              </TableBody>
            </CustomTable>
          </TableContainer>
          {/* <Box margin={2}>
            <Button
              size='medium'
              variant='outlined'
              sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
              onClick={selectButtonClick}
            >
              Select All
            </Button>
          </Box> */}

          {!pageLoader && (count !== 0 || count !== '0') && (
            <TablePagination
              className='customers_footer'
              sx={{ marginRight: 0 }}
              rowsPerPageOptions={[6, 10, 20, 50]}
              component='div'
              count={count}
              rowsPerPage={limit}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      ) : displaySearch ? (
        <Box className='rounded border-2 bg-white p-3 text-center' margin={3} sx={{ color: getColorCode('redToBlue') }}>
          No data found
        </Box>
      ) : (
        <Box className='rounded border-2 bg-white p-3 text-center' margin={3} sx={{ color: getColorCode('redToBlue') }}>
          There are no devices assigned to this MSO.
        </Box>
      )}
    </>
  )
}

export default DeviceDetails
