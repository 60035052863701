/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, CardContent, FormControl, Grid2, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import xcpemSec from '../../../../axiosInstances/xcpemSec'
import Loader from '../../../../components/Loader'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { getColorCode } from '../../../../utils/helper'

const SpeedSettings = ({
  setSnackBarInfo,
  mqttRes,
  callMqtt,
  setFetchingRouterSettings
}: {
  setSnackBarInfo?: any
  mqttRes?: any
  callMqtt?: any
  setFetchingRouterSettings?: any
}) => {
  const [SpeedLimitValue, setSpeedLimitValue] = useState<number | string>('')
  const [SpeedLimitUnit, setSpeedLimitUnit] = useState('Mbps')
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isResetLoading, setIsResetLoading] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(true)

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const handleUpdateSpeed = async (action: 'update' | 'reset') => {
    // Set loading state based on action
    if (action === 'update') setIsUpdateLoading(true)
    else if (action === 'reset') setIsResetLoading(true)

    // Define payload based on action
    const speedPayload = {
      gw_uuid: activeGw_Uid,
      speedlimit:
        action === 'update'
          ? {
              downspeed_value: SpeedLimitValue,
              upspeed_value: SpeedLimitValue,
              downspeed_unit: SpeedLimitUnit,
              upspeed_unit: SpeedLimitUnit
            }
          : {
              downspeed_value: null,
              upspeed_value: null
            }
    }

    try {
      const response = await xcpemSec.post(`/api/v4/iot/speedlimit`, speedPayload)
      if (response.status === 200) {
        setSnackBarInfo({
          isOpen: true,
          message: response.data?.message,
          type: 'Closed'
        })
        // if (action === 'reset') handleFetchSpeed()
        if (action === 'reset') {
          setFetchingRouterSettings(true)
          callMqtt()
        }
      }
    } catch (error: any) {
      console.log('error', error)
      setSnackBarInfo({
        isOpen: true,
        message: error?.message,
        type: 'Closed'
      })
    } finally {
      // Reset loading state
      setButtonDisable(true)
      if (action === 'update') {
        setIsUpdateLoading(false)
      } else if (action === 'reset') {
        setIsResetLoading(false)
      }
    }
  }

  // const handleFetchSpeed = async () => {
  //   try {
  //     setIsLoading(true)
  //     const response = await xcpemSec.get(`/api/v4/iot/speedlimit?gw_uuid=${activeGw_Uid}`)
  //     setSpeedLimitValue(response?.data?.downspeed_value || 0)
  //     setSpeedLimitUnit(response?.data?.downspeed_unit || 'Mbps')
  //     setIsLoading(false)
  //   } catch (error: any) {
  //     console.log('error', error)
  //     setIsLoading(false)
  //     setSnackBarInfo({
  //       isOpen: true,
  //       message: error?.message,
  //       type: 'Closed'
  //     })
  //   }
  // }

  useEffect(() => {
    // activeGw_Uid && handleFetchSpeed()
    if (mqttRes) {
      setSpeedLimitValue(
        mqttRes?.params?.rl_download_rate === '0' || mqttRes?.params?.rl_download_rate === ''
          ? 0
          : Number(mqttRes?.params?.rl_download_rate) || 0
      )
      setSpeedLimitUnit(
        mqttRes?.params?.rl_download_rate === '0' || mqttRes?.params?.rl_download_rate === ''
          ? 'Mbps'
          : mqttRes?.params?.rl_download_unit || 'Mbps'
      )
      setIsLoading(false)
    }
  }, [activeGw_Uid, mqttRes])

  return (
    <>
      <Box className='bg-white info-card-dev mb-4 pb-2'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box className='font-bold  title pt-2 pl-10 border-b-2 pb-2 mb-2'>Speed Limit</Box>
            <Card sx={{ boxShadow: 'none', mt: 2, mb: 1 }}>
              <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                  <CardContent style={{ padding: 10 }} className='flex gap-3'>
                    <FormControl fullWidth className=''>
                      <InputLabel sx={{ '&.Mui-error': { color: getColorCode('redToBlue') } }}>Speed</InputLabel>
                      <Select
                        value={SpeedLimitValue}
                        label='Speed'
                        onChange={(e: any) => {
                          setSpeedLimitValue(e.target.value)
                          setButtonDisable(false)
                        }}
                        sx={{
                          '&.Mui-error': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: getColorCode('redToBlue')
                            }
                          },
                          color: {
                            '&.Mui-error': { color: getColorCode('redToBlue') }
                          }
                        }}
                      >
                        <MenuItem value={0} disabled>
                          None
                        </MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={256}>256</MenuItem>
                        <MenuItem value={512}>512</MenuItem>
                      </Select>
                    </FormControl>
                    {SpeedLimitValue !== '' && SpeedLimitValue !== 0 && (
                      <FormControl className='w-50'>
                        <InputLabel sx={{ '&.Mui-error': { color: getColorCode('redToBlue') } }}>Speed Unit</InputLabel>
                        <Select
                          value={SpeedLimitUnit}
                          label='Speed Unit'
                          onChange={e => {
                            setSpeedLimitUnit(e.target.value)
                          }}
                          sx={{
                            '&.Mui-error': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: getColorCode('redToBlue')
                              }
                            },
                            color: {
                              '&.Mui-error': { color: getColorCode('redToBlue') }
                            }
                          }}
                          disabled
                        >
                          <MenuItem value='kbps'>Kbps</MenuItem>
                          <MenuItem value={'Mbps'}>Mbps</MenuItem>
                          <MenuItem value={'Gbps'}>Gbps</MenuItem>
                          <MenuItem value={'Tbps'}>Tbps</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 2, marginTop: 2 }}>
                    <Button
                      type='button'
                      variant='contained'
                      sx={{
                        background: getColorCode('infoBlue'),
                        color: '#fff',
                        '&:hover': {
                          background: getColorCode('infoBlue')
                        }
                      }}
                      onClick={() => handleUpdateSpeed('update')}
                      disabled={isUpdateLoading || buttonDisable}
                    >
                      {isUpdateLoading ? 'Updating...' : 'Update'}
                    </Button>
                    <Button
                      type='button'
                      variant='contained'
                      sx={{
                        background: getColorCode('infoBlue'),
                        color: '#fff',
                        '&:hover': {
                          background: getColorCode('infoBlue')
                        }
                      }}
                      onClick={() => handleUpdateSpeed('reset')}
                      disabled={isResetLoading || SpeedLimitValue === '' || SpeedLimitValue === 0}
                    >
                      {isResetLoading ? 'Resetting...' : 'Reset'}
                    </Button>
                  </Box>
                </Grid2>
              </Grid2>
            </Card>
          </>
        )}
      </Box>
    </>
  )
}

export default SpeedSettings
